.main_container {
    justify-content: center !important;
    align-items: center;
}

.form_blk_container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90% !important;
    align-self: 'center';
    align-items: center;
}

.text_input {
    width: 30rem;
    height: 5rem;

}

.inner_div {
    position: relative;
    background: var(--light);
    padding: 4rem;
    margin: auto;
    border-radius: 1rem;
    z-index: 3;
    justify-content: center;

    >.back_btn {
        width: 4.8rem;
        height: 4.8rem;
        background: transparent;
        padding: 0;
        border: 0;

        &:hover {
            svg {
                path {
                    fill: var(--prime_color);
                }
            }
        }
    }
}

.input_field_div {
    width: 32rem
}

.text_area {
    width: 100%;
    border: 0.1rem solid darkgray;
    background: white;
    transition: all ease 0.5s;
}

.react-tel-input .form-control {
	height: 6rem !important;
	width: 100% !important;;
}

.input-placeholder{
    &::placeholder{
        font-size: 14px !important; 
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(var(--dark_rgb), 0.2) !important;
}