#keyboard-icon {
	position: absolute;
	z-index: 1;
	margin-top: 12px;
	margin-left: 12px;
}

input::placeholder {
	font: 20px/3 sans-serif;
	color: #bcb9b9;
}
