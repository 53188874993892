.lawyer_Directory_card {
	position: relative;
	border: 0.2rem solid transparent;
	border-radius: 1rem;
	margin-bottom: 2rem;
	width: 100%;
	white-space: nowrap;
	padding: 2.2rem 4.8rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: $white_color;
	-webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	overflow: hidden;
	align-items: center;
	justify-content: space-around;

	&:hover {
		border-color: $prime_color;
		-webkit-transition: all ease-in-out 0.3s;
		transition: all ease-in-out 0.3s;
	}

	@media (max-width: 991px) {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	@media (max-width: 425px) {
		font-size: 1rem;
	}

	> .pop_btn {
		@include pos($z: 1);
	}

	h5 {
		margin-bottom: 1rem;
	}

	p {
		font-size: 2rem;
		line-height: 3rem;

		@media (max-width: 425px) {
			font-size: 1.6rem;
		}

		@media (max-width: 375px) {
			font-size: 1.3rem;
		}
	}

	&.price_blk {
		margin-right: 4rem;
	}

	.profile_blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-width: 35.4rem;
		max-width: 70px;
		margin-top: 0;
		white-space: pre-wrap;

		@media (max-width: 991px) {
			width: 100%;
			margin-bottom: 2rem;
		}

		.img_blk {
			width: 7.2rem;
			min-width: 7.2rem;
			height: 7.2rem;

			@media (max-width: 375px) {
				margin-left: 1.5rem;
			}
		}

		.text_blk {
			margin-left: 5rem;
		}
	}

	.phone {
		min-width: 22.5rem;
		width: 22.5rem;
		text-align: center;

		@media (max-width: 991px) {
			width: 50%;

			display: -webkit-box;

			display: -ms-flexbox;

			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
		}

		@media (max-width: 580px) {
			margin-bottom: 1rem;
		}
	}

	.email {
		min-width: 34rem;
		width: 34rem;
		margin-right: 3rem;
		text-align: center;

		@media (max-width: 991px) {
			width: 50%;
			margin-right: 0;
		}
	}

	.toggle1 {
		min-width: 24rem;
		width: 24rem;
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 580px) {
			width: 100%;
			margin-bottom: 2rem;
		}

		.switch {
			width: 12rem;
			min-width: 12rem;

			input {
				&:checked {
					& + em {
						&:before {
							-webkit-transform: translateX(4.5rem);
							-ms-transform: translateX(4.5rem);
							transform: translateX(4.5rem);
						}

						&:after {
							background: var(--second_color);
						}
					}

					& ~ span {
						text-align: left;

						&:after {
							content: "Visible";
						}
					}
				}
			}

			> em {
				&:before {
					-webkit-transform: translateX(-4.5rem);
					-ms-transform: translateX(-4.5rem);
					transform: translateX(-4.5rem);
				}

				&:after {
					background: #494949;
				}
			}

			span {
				@include pos($z: 1);
				@include flex(center);
				text-align: right;
				padding: 0 1rem;

				&:after {
					content: "Invisible";
					width: 100%;
					color: var(--light);
				}
			}
		}
	}

	.toggle2 {
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 580px) {
			width: 100%;
		}

		.switch {
			input {
				&:checked {
					& ~ span {
						&:after {
							content: "Active";
						}
					}
				}
			}

			span {
				&:after {
					position: absolute;
					top: 100%;
					content: "De-active";
				}
			}
		}
	}

	.switch {
		position: relative;
		margin-bottom: 3rem;
		z-index: 2;
	}

	.phone_email {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		@media (max-width: 991px) {
			width: 100%;
			height: 14rem;
			margin-bottom: 2rem;
			justify-content: space-around;
			flex-direction: column;
		}

		@media (max-width: 580px) {
			flex-direction: column;
			height: 14rem;
			justify-content: space-around;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-ms-flex-align: start;
			align-items: start;
		}
	}

	.mob_toggle_blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;

		@media (max-width: 580px) {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-ms-flex-align: start;
			align-items: start;
		}
	}

	ul.icons {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		z-index: 1;

		li {
			margin-right: 2rem;

			&:last-child {
				margin-right: 0;
			}

			.icon_blk {
				display: block;
				min-width: 5rem;
				width: 5rem;
				height: 5rem;
				padding: 0;
				border: 0;
				background: transparent;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	ul.edit_icons {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 10rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		li {
			&:last-child {
				margin-right: 0;
			}

			.icon_blk {
				min-width: 5rem;
				width: 5rem;
				height: 5rem;
				padding: 0;
				border: 0;
				background: transparent;
			}
		}
	}
}
.action_btn_container {
	width: 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 2rem;
	@media (max-width: 991px) {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 2rem;
		margin-right: 2rem;
	}

	@media (max-width: 580px) {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 2rem;
		margin-right: 2rem;
	}
}

.firm_card {
	h5 {
		@media (max-width: 991px) {
			word-break: break-all;
			width: 250px;
		}
	}

	p {
		@media (max-width: 991px) {
			word-break: break-all;
			width: 250px;
		}
	}
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.dropimg {
width: 5.1rem; /* Adjust the width as needed */
height: 5.1rem; /* Adjust the height as needed */
cursor: pointer;
display: inline !important;
}