
#contact_card {
	position: relative;
	border-radius: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	white-space: nowrap;
	padding: 1rem 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 1rem;
	overflow: hidden;
	align-items: center;

	&:hover {
		border: 1px solid $prime_color;
		-webkit-transition: all ease-in-out 0.3s;
		transition: all ease-in-out 0.3s;
	}

	@media (max-width: 991px) {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	h4 {
		color: #114d96;
		margin-bottom: 1rem;

        @media(max-width:991px){
            font-size: small;
        }
	}

	


	

	.profile_blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-top: 0;

		@media (max-width: 991px) {
			width: 100%;
		}

		.text_blk {
			margin-left: 2.5rem;
			margin-top: auto;

			@media (max-width: 1024px) {
				margin-left: 4rem;
			}
		}
	}
}