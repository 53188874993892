#admin_manage_messages {
	.message_Card {
		width: 100%;
		display: flex;
		align-items: start;
		background: #ffffff;
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
		border-radius: 1rem;
		padding: 4rem 7.7rem;
		margin-bottom: 2rem;
		justify-content: space-between;

		@media (max-width: 580px) {
			padding: 4rem 2rem;
			flex-direction: column;
			align-items: start;
		}

		div {
			flex: 0 0 20%;

			&.message_by {
				display: flex;
				flex-direction: column;
				align-items: center;
				@media (max-width: 580px) {
					align-items: start;
					margin-bottom: 2rem;
					margin-top: 2rem;
				}
			}
			&.actions {
				display: flex;
				flex-direction: column;
				align-items: end;
				@media (max-width: 580px) {
					align-items: start;
				}
			}
		}
		h5 {
			font-size: 2.4rem;
			line-height: 3.6rem;
			margin-bottom: 1rem;
		}
		p {
			font-size: 2rem;
			line-height: 3rem;
		}
		a {
			font-size: 2rem;
			line-height: 3rem;
			color: #114d96;
		}
	}
	.tabs {
		display: flex;
		align-items: center;
		flex-flow: wrap;
		margin-top: 5.5rem;
		margin-bottom: 1rem;

		button {
			border: 0.2rem solid #114d96;
			border-radius: 0.5rem;
			font-weight: 500;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #114d96;
			margin-right: 2rem;
			background-color: transparent;
			width: 27rem;
			min-width: 27rem;

			@media (max-width: 769px) {
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
			@media (max-width: 480px) {
				flex: 1;
				margin-right: 0;
			}

			&:hover {
				background-color: #a5c3e7;
				// color: $white_color;
				transition: all ease-in-out 0.3s;
			}
			&.active_btn {
				background-color: #114d96;
				color: #ffffff;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}