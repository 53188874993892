#settings {
	.settings_outer {
		background-color: $white_color;
		padding: 4rem;
		border-radius: 0.8rem;
		margin-top: 2rem;
		.top_sec {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			@media (mƒx-width: 768px) {
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				margin-bottom: 3rem;
			}

			.left_blk {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				@media (max-width: 768px) {
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					margin-bottom: 3rem;
				}

				.sec_logo {
					width: 20rem;
					min-width: 20rem;
					margin-right: 3rem;

					img {
						width: 100%;
						display: block;
					}
				}
			}
			.edit-link {
				font-size: 2rem;
				font-weight: 600;
				color: $prime_color;
				text-decoration: underline;
			}
		}
		.input-label {
			font-size: 2.4rem;
			color: #c1bfc3;
			line-height: 3.6rem;
			margin-bottom: 2rem;
		}
		input {
			outline: none;
			border: none;
			font-size: 2rem;
			line-height: 3rem;
			background-color: transparent;
		}
		.input-field {
			width: 100%;
			display: block;
			padding-left: 0;
			border-bottom: 0.2rem solid #ebebeb;
			padding-bottom: 1rem;
		}
		.num-entry-sect {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
		}
		.group {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			.cntry-code-list {
				outline: none;
				height: 5.2rem;
				font-size: 2rem;
				line-height: 3rem;
				border: none;
				width: 8rem;
				min-width: 8rem;
				border-bottom: 0.2rem solid #ebebeb;
				background: transparent;
				margin-right: 2rem;
			}
		}
		.firm-info {
			color: $black_color;
			padding: 2.5rem;
			font-size: 2rem;
			margin-top: 2rem;
			width: 100%;
			min-height: 26rem;
			height: auto;
			overflow-y: scroll;
			border: 0.2rem solid #ebebeb;
			border-radius: 0.5rem;
			resize: none;
			// overflow: hidden;
		}
		.update_btn {
			min-width: 25rem;
			width: 25rem;
		}
	}
}
