.MuiInputBase-input {
	font-size: 16px !important;
	margin-left: 25px !important;
}

.MuiBox-root {
	font-size: 16px !important;
}

.icon-start {
	width: 30px;
	height: 20px;
	position: absolute;
	top: 19px;
	left: 10px;
}

.MuiInputBase-root {
	padding-left: 2rem !important;
}

.MuiChip-root {
	margin-left: 40px;
}

.MuiInputBase-input {
	margin-left: 0px !important;
}

.MuiAutocomplete-tag{
	font-size: 12px !important;
}

.css-i4bv87-MuiSvgIcon-root{
	font-size: 3.5rem !important;
}
