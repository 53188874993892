.main_container {
    justify-content: center !important;
    align-items: center;
}

.form_blk_container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90% !important;
    align-self: 'center';
    align-items: center;
}

.text_input {
    width: 30rem;
    height: 5rem;

}

.inner_div {
    position: relative;
    background: var(--light);
    padding: 4rem;
    margin: auto;
    border-radius: 1rem;
    z-index: 3;
    justify-content: center;

    >.back_btn {
        width: 4.8rem;
        height: 4.8rem;
        background: transparent;
        padding: 0;
        border: 0;

        &:hover {
            svg {
                path {
                    fill: var(--prime_color);
                }
            }
        }
    }
}

.input_field_div {
    width: 32rem
}

.text_area {
    width: 100%;
    border: 0.1rem solid darkgray;
    background: white;
    transition: all ease 0.5s;
}

.react-tel-input .form-control {
    width: 100% !important;
    ;
}

.editor-class {
    background-color:lightgray;
    padding: 1rem;
    min-height: 10rem;
    border: 1px solid #ccc;
    .jodit-wysiwyg{
        min-height: 200px !important;
    }
  }

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0.5rem !important;
}
.react-date-picker__wrapper{
    display: flex !important;
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    border: 0 ,
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 1.5rem 1.2rem !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    min-height: 1rem !important;
}