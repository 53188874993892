#firm_lawyer {
    #top_blk {
        margin-bottom: 3.5rem;
    }
    .lawyer_card {
        @media (max-width: 991px) {
            flex-direction: row;
        }
        @media (max-width: 860px) {
            flex-direction: column;
        }

        .profile_blk {
            min-width: initial;
            width: 100%;
            flex: 0 0 33%;
            margin-right: 0;
            @media (max-width: 860px) {
                display: flex;
                align-items: center;
                justify-content: start;
            }
        }
        .phone_email {
            flex: 0 0 67%;

            .phone {
                flex: 0 0 55%;
                margin-right: 0;

                @media (max-width: 860px) {
                    align-items: start;
                }
            }
            .email {
                flex: 0 0 45%;
                margin-right: 0;
                @media (max-width: 860px) {
                    align-items: start;
                }
            }
        }
    }
}
