#top_bar {
    background-color: $parrot_green;
    /* height: 6rem; */
    padding: 1.8rem 0;
    ul {
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        list-style-type: none;
        padding-left: 0;

        li {
            margin: 0 1.2rem;
        }
    }
}
