
@mixin pos($position, $position2, $position3, $position4) {
    position: $position $position2 $position3 $position4;
  }

._contact_modal {
    position: relative;
    background: var(--light);
    padding: 4rem 9rem;
    margin: auto;
    border-radius: 1rem;
    z-index: 3;

    @media (max-width: 768px) {
        padding: 3rem 7rem;
        font-size: 1.7rem;
    }

    @media (max-width: 425px) {
        padding: 3rem 5rem 2rem 5rem;
        font-size: 1.2rem;
    }

    @media (max-width: 375px) {
        padding: 3rem 5rem 2rem 5rem;
        font-size: 1.2rem;
    }

    > .back_btn {
        @include pos(2rem, null, null, 2rem);
        width: 4.8rem;
        height: 4.8rem;
        background: transparent;
        padding: 0;
        border: 0;

        &:hover {
            svg {
                path {
                    fill: var(--prime_color);
                }
            }
        }
    }

    > .x_btn {
        & + h1,
        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6 {
            padding-right: 3rem;
        }
    }
}

.contact_card {
    // flexDirection: 'column',
    margin-bottom: 2rem;
    width: 100%;
    white-space: nowrap;
    padding: 2.2rem 4.8rem;
    display: flex;
    // background: red;
    border-radius: 1rem;
    align-items: center;
    // justifyContent: 'space-around',
    // zIndex: "9999999 !important",
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.contact_card:hover {
    cursor: pointer;
  }