#top_blk {
    display: flex;
    align-items: center;
    .img_blk {
        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;
        margin-right: 2rem;
        cursor: pointer;

        img {
            width: 100%;
        }
    }
}
