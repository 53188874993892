#NewsArticles {

    .row {
        @media (max-width: 375px) {
            margin-left: -2.01rem;
        }
    }

    .top_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;

        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .left_blk {
			display: flex;
			align-items: center;
            // width: 100%;

            @media (max-width: 991px) {
				width: 100%;
			}

			.search_blk {
				display: flex;
				position: relative;
				align-items: center;
				border: 0.1rem solid #bdbec0;
				border-radius: 0.5rem;
				height: 6rem;
				min-width: 37.4rem;
				width: 37.4rem;
				background-color: $white_color;

				@media (max-width: 991px) {
					position: relative;
					bottom: 0;
					top: 100%;
					left: 0;
					right: 0;
					transform: translate(0, 50%);
					width: 100%;
				}

                @media (max-width: 375px) {
                    margin-left: -1rem;
                    min-width: 35rem;
                    width: 35rem;
                }

                @media (max-width: 320px) {
                    margin-left: -1rem;
                    min-width: 30rem;
                    width: 30rem;
                }

				.img_blk {
					width: 4.8rem;
					min-width: 4.8rem;
					height: 4.8rem;
					margin: 0 1rem 0 2rem;

					img {
						width: 100%;
						display: block;
					}
				}

				input {
					border: initial;
					width: 100%;
					padding: 0 1rem;
				}
			}

			.filter_blk {
				min-width: 6rem;
				width: 6rem;
				height: 6rem;
				margin-left: 2rem;
			}
		}
        

        ul {
            display: flex;
            align-items: center;
            flex-flow: wrap;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;
            @media (max-width: 991px) {
                margin-top: 2rem;
            }
            @media (max-width: 480px) {
                flex-direction: column;
                align-items: flex-start;
                flex-wrap: initial;
                width: 100%;
            }

            li {
                margin-right: 2rem;

                @media (max-width: 768px) {
                    margin-bottom: 1rem;
                }
                @media (max-width: 480px) {
                    margin-right: 0;
                    width: 100%;
                }

                &:last-child {
                    margin-right: 0;
                }
                button {
                    padding: 1.5rem 3rem;
                    width: auto;
                    border: 0.2rem solid $prime_color;
                    border-radius: 0.5rem;
                    background-color: transparent;
                    font-weight: 500;
                    font-size: 2.4rem;
                    line-height: 3.6rem;
                    color: $prime_color;


                    @media (max-width: 480px) {
                        width: 100%;
                    }

                    &.active_btn {
                        color: $white_color;
                        background-color: $prime_color;
                    }
                    &:hover {
                        background-color: #a5c3e7;
                        // color: $white_color;
                        transition: all ease-in-out 0.3s;
                    }
                    &.active {
                        background-color: $prime_color;
                        color: $white_color;
                    }
                }
            }
        }
    }

    ul {
        list-style-type: none;
        border-bottom: 0.2rem solid #bcb9b9;
        padding-left: 0;
        display: flex;
        align-items: center;
        margin-top: 2.6rem;
        margin-bottom: 0;

        li {
            margin-right: 8rem;

            a {
                font-weight: 500;
                font-size: 2.4rem;
                line-height: 3.6rem;
                color: #bcb9b9;
                padding-bottom: 1rem;
                display: block;
                margin-bottom: -0.2rem;

                &.active {
                    color: $prime_color;
                    border-bottom: 0.2rem solid $prime_color;
                }
            }
        }
    }

    .tabs {
        display: flex;
        align-items: center;
        flex-flow: wrap;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        border-bottom: initial;

        @media (max-width: 480px) {
            flex-direction: column;
        }

        li {
            margin-right: 2rem;

            @media (max-width: 480px) {
                flex: 1;
                width: 100%;
                margin-right: 0;
            }

            button {
                border: 0.2rem solid $prime_color;
                border-radius: 0.5rem;
                background: transparent;
                font-weight: 500;
                font-size: 2.4rem;
                line-height: 3.6rem;
                color: $prime_color;
                width: 27rem;

                @media (max-width: 480px) {
                    // flex: 1;
                    width: 100%;
                }

                &:hover {
                    background-color: #a5c3e7;
                    // color: $white_color;
                    transition: all ease-in-out 0.3s;
                }

                &.active {
                    background-color: $prime_color;
                    color: $white_color;
                }

                @media (max-width: 991px) {
                    margin-bottom: 2rem;
                }
            }
        }

        &.tabs2 {
            button {
                width: auto;
                height: 6rem;
                padding: 0 3.6rem;

                @media (max-width: 480px) {
                    flex: 1;
                    width: 100%;
                }
            }
        }
    }
    .event_card {
        display: flex;
        padding: 2rem 4rem 2rem 2rem;
        background: #ffffff;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .img_blk {
            min-width: 27rem;
            width: 27rem;
            height: 21rem;
            margin-right: 4rem;

            @media (max-width: 480px) {
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
        .content {
            display: flex;
            flex-direction: column;

            h5 {
                font-weight: 600;
                font-size: 2.4rem;
                line-height: 3.6rem;
                margin-bottom: 1rem;
            }
            small {
                font-size: 2rem;
                line-height: 3rem;
                color: #bcb9b9;
                margin-bottom: 2.6rem;
            }
            .register {
                display: none;
            }
            // a {
            //     display: none;
            // }
        }
    }
}
