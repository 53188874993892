#resources {
    .Top_blk {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: start;
        }

        .left_blk {
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                margin-top: 2rem;
            }
            @media (max-width: 480px) {
                flex-direction: column;
                align-items: start;
            }

            @media (max-width: 425px) {
                flex-direction: row;
                align-items: end;
                width: 1rem;
            }
            .search_blk {
                display: flex;
                position: relative;
                align-items: center;
                border: 0.1rem solid #bdbec0;
                border-radius: 0.5rem;
                height: 6rem;
                min-width: 37.4rem;
                width: 37.4rem;
                background-color: $white_color;

                @media (max-width: 768px) {
                    min-width: 65rem;
                    width: 65rem;
                }

                @media (max-width: 425px) {
                min-width: 32rem;
                width: 32rem;
                }

                @media (max-width: 375px) {
                    min-width: 27rem;
                    width: 27rem;
                }

                // @media (max-width: 991px) {
                //     position: absolute;
                //     bottom: 0;
                //     top: 100%;
                //     left: 0;
                //     right: 0;
                //     transform: translate(0, 50%);
                //     width: 100%;
                // }
                .img_blk {
                    width: 4.8rem;
                    min-width: 4.8rem;
                    height: 4.8rem;
                    margin: 0 1rem 0 2rem;

                    img {
                        width: 100%;
                        display: block;
                    }
                }
                input {
                    border: initial;
                    width: 100%;
                    padding: 3px 1rem;
				    padding-top: 10px;
                }
            }
            .filter_blk {
                min-width: 6rem;
                width: 6rem;
                height: 6rem;
                margin-left: 2rem;
                @media (max-width: 480px) {
                    margin-left: 0;
                    margin-top: 1rem;
                }

                @media (max-width: 425px) {
                    margin-left: 0.8rem;
                    margin-top: 0rem;
                }
            }
        }
    }

    ul {
        list-style-type: none;
        border-bottom: 0.2rem solid #bcb9b9;
        padding-left: 0;
        display: flex;
        align-items: center;
        margin-top: 2.6rem;
        margin-bottom: 0;
        li {
            margin-right: 8rem;
            a {
                font-weight: 500;
                font-size: 2.4rem;
                line-height: 3.6rem;
                color: #bcb9b9;
                padding-bottom: 1rem;
                display: block;
                margin-bottom: -0.2rem;

                &.active {
                    color: $prime_color;
                    border-bottom: 0.1rem solid $prime_color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
            @media (max-width: 480px) {
                margin-right: 3rem;
            }
            @media (max-width: 991px) {
                margin-right: 5rem;
            }
        }
    }

    .resource_card {
        background: $white_color;
        border: 0.1rem solid #bcb9b9;
        border-radius: 1rem;
        padding: 3.7rem 7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;

        @media (max-width: 580px) {
            padding: 3rem;
        }
        @media (max-width: 991px) {
            padding: 0.7rem 0.3rem;
        }

        .file_blk {
            display: flex;

            .img_blk {
                width: 7.2rem;
                min-width: 7.2rem;
                height: 7.2rem;

                img {
                    width: 100%;
                }
            }
            .file_name_blk {
                display: flex;
                flex-direction: column;
                margin-left: 4rem;
            }
        }

        .download_blk {
            width: 7.2rem;
            min-width: 7.2rem;
            height: 7.2rem;

            img {
                width: 100%;
            }
        }
    }

    .tag_list {
        margin-bottom: 2rem;
        border-bottom: initial;
        flex-flow: wrap;
        li {
            margin-right: 1rem;

            @media (max-width: 480px) {
                margin-bottom: 1rem;
            }

            &:last-child {
                margin-right: 0;
            }
            .tag {
                background: #bcb9b9;
                border-radius: 5.5rem;
                display: flex;
                align-items: center;
                padding: 0.2rem 1.6rem;
                font-size: 2rem;
                line-height: 3.6rem;
                color: $white_color;

                .icon_blk {
                    width: 1.6rem;
                    min-width: 1.6rem;
                    height: 1.6rem;
                    margin-left: 0.8rem;
                }
            }
        }
    }
}
