#manage_lawyers {
	.Top_blk {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4rem;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: start;
		}

		.left_blk {
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				margin-top: 2rem;
			}
			@media (max-width: 480px) {
				flex-direction: column;
				align-items: start;
			}
			.search_blk {
				display: flex;
				position: relative;
				align-items: center;
				border: 0.1rem solid #bdbec0;
				border-radius: 0.5rem;
				height: 6rem;
				min-width: 37.4rem;
				width: 37.4rem;
				background-color: $white_color;

				@media (max-width: 768px) {
					min-width: 65rem;
					width: 65rem;
				}

				@media (max-width: 375px) {
					min-width: 33rem;
					width: 33rem;
				}

				// @media (max-width: 991px) {
				//     position: absolute;
				//     bottom: 0;
				//     top: 100%;
				//     left: 0;
				//     right: 0;
				//     transform: translate(0, 50%);
				//     width: 100%;
				// }
				.img_blk {
					width: 4.8rem;
					min-width: 4.8rem;
					height: 4.8rem;
					margin: 0 1rem 0 2rem;

					img {
						width: 100%;
						display: block;
					}
				}
				input {
					border: initial;
					width: 100%;
					padding: 3px 1rem;
				    padding-top: 10px;
				}
			}
			.filter_blk {
				min-width: 6rem;
				width: 6rem;
				height: 6rem;
				margin-left: 2rem;
				@media (max-width: 480px) {
					margin-left: 0;
					margin-top: 1rem;
				}
			}
		}
	}
	.tag_list {
		margin-bottom: 2rem;
		border-bottom: initial;
		flex-flow: wrap;
		display: flex;
		list-style: none;
		padding-left: 0;

		li {
			margin-right: 1rem;

			@media (max-width: 480px) {
				margin-bottom: 1rem;
			}

			&:last-child {
				margin-right: 0;
			}
			.tag {
				background: #bcb9b9;
				border-radius: 5.5rem;
				display: flex;
				align-items: center;
				padding: 0.2rem 1.6rem;
				font-size: 2rem;
				line-height: 3.6rem;
				color: $white_color;

				.icon_blk {
					width: 1.6rem;
					min-width: 1.6rem;
					height: 1.6rem;
					margin-left: 0.8rem;
				}
			}
		}
	}
	.sent_ref_body {
		display: flex;
		flex-direction: column;
		align-items: center;

		.img_blk {
			width: 15rem;
			min-width: 15rem;
			height: 15rem;
			margin-bottom: 2.6rem;
		}
		h3 {
			font-size: 4rem;
			margin-bottom: 2.6rem;
			text-align: center;
		}
		.user_profile_blk {
			width: 9rem;
			min-width: 9rem;
			height: 9rem;
			margin-bottom: 2rem;
		}
	}

	//lawyer list popup

	.lawyer_list_block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 2rem;
		border-bottom: 0.2rem solid #dfe0eb;
		margin-bottom: 2rem;
		@media (max-width: 991px) {
			flex-direction: column;
			align-items: flex-start;
		}

		.user_blk {
			display: flex;
			align-items: center;
			.user_profile_blk {
				width: 7.2rem;
				min-width: 7.2rem;
				height: 7.2rem;
			}

			.user_name_blk {
				margin-left: 2rem;
				p {
					font-size: 2.4rem;
					line-height: 3.6rem;
				}
			}
		}
		.send_btn {
			width: 17rem;
			@media (max-width: 991px) {
				margin-top: 2rem;
			}
		}
	}
}
