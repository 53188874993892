#media_library {
	.Top_blk {
		display: flex;
		align-items: center;
		justify-content: space-between;

	}
	.left_blk {
		display: flex;
		// align-items: center;
		float:right;

		@media (max-width: 991px) {
				float: unset;
				margin: auto;
			}


		.search_blk {
			display: flex;
			position: relative;
			align-items: center;
			border: 0.1rem solid #bdbec0;
			border-radius: 0.5rem;
			height: 6rem;
			min-width: 37.4rem;
			width: 37.4rem;
			background-color: $white_color;

			@media (max-width: 991px) {
				width: 100%;
			}

			@media (max-width: 375px) {
				min-width: 33rem;
				width: 33rem;
			}

			@media (max-width: 320px) {
				min-width: 25rem;
				width: 25rem;
			}

			// @media (max-width: 991px) {
			// 	position: relative;
			// 	bottom: 0;
			// 	top: 100%;
			// 	left: 0;
			// 	right: 0;
			// 	transform: translate(0, 50%);
			// 	width: 100%;
			// }

			.img_blk {
				width: 4.8rem;
				min-width: 4.8rem;
				height: 4.8rem;
				margin: 0 1rem 0 2rem;

				img {
					width: 100%;
					display: block;
				}
			}

			input {
				border: initial;
				width: 100%;
				padding: 0 1rem;
			}
		}

		.filter_blk {
			min-width: 6rem;
			width: 6rem;
			height: 6rem;
			margin-left: 2rem;
		}
	}

	ul {
		list-style-type: none;
		border-bottom: 0.2rem solid #bcb9b9;
		padding-left: 0;
		display: flex;
		align-items: center;
		margin-top: 2.6rem;
		margin-bottom: 0;

		li {
			margin-right: 8rem;

			a {
				font-weight: 500;
				font-size: 2.4rem;
				line-height: 3.6rem;
				color: #bcb9b9;
				padding-bottom: 1rem;
				display: block;
				margin-bottom: -0.2rem;

				&.active {
					color: $prime_color;
					border-bottom: 0.2rem solid $prime_color;
				}
			}
		}
	}

	.tabs {
		display: flex;
		align-items: center;
		flex-flow: wrap;
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		border-bottom: initial;

		@media (max-width: 480px) {
			flex-direction: column;
		}

		li {
			margin-right: 2rem;

			@media (max-width: 480px) {
				flex: 1;
				width: 100%;
				margin-right: 0;
			}

			button {
				border: 0.2rem solid $prime_color;
				border-radius: 0.5rem;
				background: transparent;
				font-weight: 500;
				font-size: 2.4rem;
				line-height: 3.6rem;
				color: $prime_color;
				width: 27rem;

				@media (max-width: 480px) {
					// flex: 1;
					width: 100%;
				}

				&:hover {
					background-color: #a5c3e7;
					// color: $white_color;
					transition: all ease-in-out 0.3s;
				}

				&.active {
					background-color: $prime_color;
					color: $white_color;
				}

				@media (max-width: 991px) {
					margin-bottom: 2rem;
				}
			}
		}

		&.tabs2 {
			button {
				width: auto;
				height: 6rem;
				padding: 0 3.6rem;

				@media (max-width: 480px) {
					flex: 1;
					width: 100%;
				}
			}
		}
	}

	.library {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 2rem;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		.img_blk {
			min-width: 27rem;
			width: 27rem;
			// height: 40.5rem;
			margin-right: 2rem;
			border-radius: 1rem;
			overflow: hidden;

			&:last-child {
				margin-right: 0;
			}

			@media (max-width: 991px) {
				margin-bottom: 2rem;
			}

			@media (max-width: 768px) {
				margin-right: 0;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.podcast_img_blk{
            min-width: 27rem;
            width: 27rem;
            height: 33.5rem;
            margin-right: 2rem;
            border-radius: 1rem;
            overflow: hidden;
            &:last-child {
                margin-right: 0;
            }
            @media (max-width: 991px) {
                margin-bottom: 2rem;
            }
            @media (max-width: 768px) {
                margin-right: 0;
            }
        }

	}

	.resource_card {
		background: $white_color;
		border: 0.1rem solid #bcb9b9;
		border-radius: 1rem;
		padding: 3.7rem 7rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 2rem;

		&.active_blk {
			background: #d9eaff;
			border: 0.1rem solid #114d96;
			border-radius: 1rem;
		}

		.file_blk {
			display: flex;

			.img_blk {
				width: 7.2rem;
				min-width: 7.2rem;
				height: 7.2rem;

				img {
					width: 100%;
				}
			}

			.file_name_blk {
				display: flex;
				flex-direction: column;
				margin-left: 4rem;
			}
		}

		.download_blk {
			width: 7.2rem;
			min-width: 7.2rem;
			height: 7.2rem;

			img {
				width: 100%;
				cursor: "pointer";
			}
		}
	}
}

.library {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 2rem;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (max-width: 375px) {
		margin-left: -2.01rem;
	}

	.img_blk {
		min-width: 27rem;
		width: 27rem;
		height: 40.5rem;
		margin-right: 2rem;
		border-radius: 1rem;
		overflow: hidden;

		&:last-child {
			margin-right: 0;
		}

		@media (max-width: 991px) {
			margin-bottom: 2rem;
		}

		@media (max-width: 768px) {
			margin-right: 0;
		}

		img {
			display: block;
			width: 100%;
		}
	}
	.podcast_img_blk{
		min-width: 27rem;
		width: 27rem;
		margin-right: 2rem;
		border-radius: 1rem;
		overflow: hidden;
		&:last-child {
			margin-right: 0;
		}
		@media (max-width: 991px) {
			margin-bottom: 2rem;
		}
		@media (max-width: 768px) {
			margin-right: 0;
		}
	}

}

.ctn-main-heading {
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.webinar_card {
	margin-top: 3rem;
	border-radius: 1rem;
	width: 70rem;
	padding: 2rem;
	display: flex;
	background: #ffffff;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;

	@media (max-width: 580px) {
		flex-direction: column;
		align-items: center;
	}

	.card-image {
		width: 27rem;
		min-width: 27rem;
		height: 21rem;
		margin-right: 2rem;

		@media (max-width: 580px) {
			margin-right: 0;
			margin-bottom: 2rem;
		}

		.card-image img {
			width: 100%;
			display: block;
			border-radius: 1rem;
		}
	}

	.webnr-date {
		margin: 0;
		text-align: right;
		color: #bcb9b9;
	}

	.card-detail {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 580px) {
			align-items: center;
			justify-content: center;
		}

		@media (max-width: 425px) {
			margin-top: 2rem !important;
		}


		.card-title {
			width: 38rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			height: 6rem;
			font-size: 4rem;
			font-weight: 600;
			color: $prime_color;
			line-height: 6rem;
			margin: 0;
		}

		.evnet-by {
			color: $prime_color;
			font-size: 2.4rem;
			font-weight: 400;
			margin: 0;
			line-height: 3.6rem;
		}

		a {
			color: $white_color;
			font-size: 2.4rem;
			line-height: 3.6rem;
			font-weight: 500;
			width: 25rem;
			min-width: 25rem;

			@media (max-width: 580px) {
				margin: 2rem 0;
			}
		}
	}
}

.event_card {
	display: flex;
	padding: 2rem 4rem 2rem 2rem;
	background: #ffffff;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;

	@media (max-width: 480px) {
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 425px) {
		min-width: 36rem;
		width: 36rem;
	}

	@media (max-width: 320px) {
		min-width: 30rem;
		width: 30rem;
	}

	.img_blk {
		min-width: 27rem;
		width: 27rem;
		height: 21rem;
		margin-right: 4rem;

		@media (max-width: 480px) {
			margin-right: 0;
			margin-bottom: 2rem;
		}

		@media (max-width: 425px) {
			min-width: 32rem;
			width: 32rem;
		}

		@media (max-width: 320px) {
			min-width: 26.3rem;
			width: 26.3rem;
		}
	}

	.content {
		display: flex;
		flex-direction: column;

		h5 {
			font-weight: 600;
			font-size: 2.4rem;
			line-height: 3.6rem;
			margin-bottom: 1rem;
			color: $black_color;
		}

		small {
			font-size: 2rem;
			line-height: 3rem;
			color: #bcb9b9;
			margin-bottom: 2.6rem;
		}

		a {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: $prime_color;

			img {
				width: 2.4rem;
				min-width: 2.4rem;
				height: 2.4rem;
				margin-left: 2rem;
			}
		}
	}
}
