html {
	font-size: 10px;
	font-family: "Poppins", sans-serif;
}

@media (max-width: 2560px) {
	/* html {
		font-size: 13.333333333333332px;
	} */
}

@media (max-width: 1920px) {
	html {
		font-size: 10px;
	}
}

@media (max-width: 1680px) {
	html {
		font-size: 8.75px;
	}
}

@media (max-width: 1600px) {
	html {
		font-size: 8.333333333333334px;
	}
}

@media (max-width: 1440px) {
	html {
		font-size: 7.5px;
	}
}

@media (max-width: 1400px) {
	html {
		font-size: 7.291666666666666px;
	}
}

@media (max-width: 1368px) {
	html {
		font-size: 7.125px;
	}
}

@media (max-width: 1366px) {
	html {
		font-size: 7.114583333333333px;
	}
}

@media (max-width: 1300px) {
	html {
		font-size: 6.770833333333334px;
	}
}

@media (max-width: 1280px) {
	html {
		font-size: 6.666666666666666px;
	}
}

@media (max-width: 1208px) {
	html {
		font-size: 6.291666666666666px;
	}
}

@media (max-width: 1152px) {
	html {
		font-size: 6px;
	}
}

@media (max-width: 1024px) {
	html {
		font-size: 5.333333333333333px;
	}
}

@media (max-width: 991px) {
	html {
		font-size: 8px;
	}
}
body {
	background-color: #f4f4f4;
	&::-webkit-scrollbar {
		width: 0;
	}
}

#dashboard {
	.board_content {
		.top_blk {
			display: flex;
			align-items: center;
			margin-bottom: 5rem;
			@media (max-width: 768px) {
				flex-direction: column;
			}
			.img_blk {
				img {
					width: 15rem;
					height: 15rem;
					border-radius: 75%;
					border: 1px solid #8080801c;
				}
			}
			.text_blk {
				display: flex;
				flex-direction: column;
				margin-left: 2rem;
				@media (max-width: 768px) {
					margin-left: initial;
					margin-top: 2rem;
				}
				p {
					font-weight: 500;
					font-size: 2.4rem;
					line-height: 3.6rem;

					&:nth-child(2) {
						font-weight: 400;
						font-size: 2rem;
						line-height: 3rem;
					}
				}
			}
		}
	}
	.dashboard_card {
		cursor: pointer;
		background: #ffffff;
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
		border-radius: 2rem;
		padding: 4rem 5rem;
		display: flex;
		flex-direction: column;
		height: 100%;
		.img_blk {
			width: 7.2rem;
			min-width: 7.2rem;
			height: 7.2rem;
			margin-bottom: 2rem;

			img {
				width: 100%;
				display: block;
			}
		}
		p {
			font-size: 3.4rem;
			line-height: 5.1rem;
			color: $black_color;
		}

		.percentage-container {
			width: 11rem;
			height: 4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 1rem;
			// margin-top: 10px;
			p{
				margin: 0;
				padding-left: 1rem;
				font-size: 2rem;
				font-weight: 600;
			}
			img {
				width: 28px;
				height: 28px;
				display: block;
			}

			&.analytics-success {
				background-color: rgba(127, 255, 212, 0.28);
			}
			&.analytics-danger {
				background-color: rgba(228, 88, 88, 0.28);
			}
			&.analytics-text-success {
                color: rgb(32, 201, 151);
            }
			&.analytics-text-danger {
                color: rgb(178, 32, 32);
            }
		}
	}
}
