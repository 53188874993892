.footer {
	background-color: $prime_color;
	color: $white_color;
	display: flex;
	padding: 4rem 8rem;

	.data_container {
		display: flex;
		flex-direction: column;
		flex: 4;
		padding-right: 6rem;

		.footer_heading {
			font-size: 4rem;
			font-weight: 500;
		}
		.links_container {
			display: flex;
			flex-direction: column;
			width: 80%;
			margin: 0 auto;
			.page_links {
				display: flex;
				justify-content: space-between;
				margin: 2rem 0;
				.page_link {
					cursor: pointer;
					font-size: 2.8rem;
					font-weight: 500;
				}
			}
			.social_links {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 70%;
				margin: 2rem auto;

				.social_image {
					cursor: pointer;
					height: 40px;
					width: 40px;
					display: flex;
					align-items: center;
				}
			}
			.copyright {
				align-self: center;
				font-size: 2.4rem;
			}
		}
	}
	.logo_container {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-left: 1px solid rgba(255, 255, 255, 0.25);
		padding-left: 6rem;
		.available {
			font-size: 5rem;
			font-weight: 600;
			margin: 2rem 0;
		}
		.app_links {
			display: flex;
			justify-content: space-between;
			width: 80%;
			height: 10rem;
			.logo_image {
				cursor: pointer;
				height: 100%;
				width: 100%;
			}
		}
	}
}
@media (max-width: 768px) {
	.footer {
		flex-direction: column;
		padding: 4rem !important;

		.data_container {
			padding: 0 !important;

			.links_container {
				width: 90% !important;

				.copyright {
					margin-bottom: 2rem;
				}
			}
		}
		.logo_container {
			padding: 0 !important;
			border-left: none !important;
			.app_links {
				width: 30% !important;
			}
		}
	}
}
@media (max-width: 425px) {
	.footer {
		padding: 2rem !important;

		.data_container {
			padding: 0 !important;
			.footer_heading {
				font-size: 2.2rem;
			}
			.text {
				font-size: 1.4rem !important;
			}
			.links_container {
				.page_links {
					margin: 1rem 0 !important;
					flex-direction: column;
					align-items: center;
					.page_link {
						font-size: 2rem !important;
					}
				}
				.social_links {
					width: 100%;
					margin: 1rem auto;
					.social_image {
						height: 30px !important;
						width: 30px !important;
					}
				}
				.copyright {
					font-size: 1.4rem !important;
				}
			}
		}
		.logo_container {
			.available {
				font-size: 3.5rem !important;
				margin: 1rem 0 !important;
			}
			.app_links {
				width: 45% !important;
				height: 5rem;
			}
		}
	}
}
