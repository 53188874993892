#manage_registered_firms_info {
	&.firm_detail_container {
		display: flex;
		flex-direction: row;

		&.firm_image {
			width: 1rem;
			height: 1rem;
		}
	}
}

.search_blk_lawyers {
	display: flex;
	position: relative;
	align-items: center;
	border: 0.1rem solid #bdbec0;
	border-radius: 0.5rem;
	height: 6rem;
	line-height: 5rem;
	min-width: 37.4rem;
	width: 37.4rem;
	background-color: $white_color;

	@media (max-width: 991px) {
		position: relative;
		bottom: 0;
		top: 100%;
		left: 0;
		right: 0;
		transform: translate(0, 50%);
		width: 100%;
	}

	.img_blk {
		width: 4.8rem;
		min-width: 4.8rem;
		height: 4.8rem;
		margin: 0 1rem 0 2rem;

		img {
			width: 100%;
			display: block;
		}
	}

	input {
		border: initial;
		width: 100%;
		padding: 0 1rem;
	}
}
.search_container {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 2rem;
	@media (max-width: 768px) {
		margin-top: 2rem;
	}
	@media (max-width: 480px) {
		flex-direction: column;
		align-items: start;
	}
}
.left_blk {
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		margin-top: 2rem;
	}
	@media (max-width: 480px) {
		justify-content: space-between;
		width: 36rem;
	}
	.search_blk {
		display: flex;
		position: relative;
		align-items: center;
		border: 0.1rem solid #bdbec0;
		border-radius: 0.5rem;
		height: 6rem;
		background-color: $white_color;

		// @media (max-width: 991px) {
		//     position: absolute;
		//     bottom: 0;
		//     top: 100%;
		//     left: 0;
		//     right: 0;
		//     transform: translate(0, 50%);
		//     width: 100%;
		// }
		.img_blk {
			width: 4.8rem;
			min-width: 4.8rem;
			height: 4.8rem;
			margin: 0 1rem 0 2rem;

			img {
				width: 100%;
				display: block;
			}
		}
		input {
			border: initial;
			width: 100%;
			padding: 3px 1rem;
			padding-top: 10px;
		}
	}
	.filter_blk {
		min-width: 6rem;
		width: 6rem;
		height: 6rem;
		margin-left: 2rem;
		@media (max-width: 480px) {
			margin-left: 0;
			margin-top: 1rem;
		}
	}
}
