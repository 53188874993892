/*
|----------------------------------------------------------------------
|       Body
|----------------------------------------------------------------------
*/

body {
	@at-root {
		svg {
			path {
				@include trans;
			}
		}
	}
}

.popup {
	&#add_lawyer_popup {
			.dp {
				width: 12rem;
				min-width: 12rem;
				height: 12rem;
				margin: 2rem auto;
			}
	
			.dp-icon {
				font-size: 22px;
				margin-top: 10px;
			}
	
			.edit-icon {
				position: absolute;
				bottom: 0;
				width: 12rem;
				height: 6rem;
			}
	
			.edit-icon:hover {
				color: white;
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0.6);
				border-radius: 5px 5px 70px 70px;
			}
		}
	&#filter_popup {
		.dropdown-menu {
			max-height: 34rem;
			overflow: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 1rem;
				height: 1rem;
				background-color: inherit;
				border-radius: 0 5rem 5rem 0;
			}
			&::-webkit-scrollbar {
				width: 1rem;
				height: 1rem;
				background-color: inherit;
				border-radius: 0 5rem 5rem 0;
			}
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
				border: 0.3rem solid transparent;
			}
		}
		.juri_dropdown {
			li {
				> label {
					@include flex;
					input {
						margin-right: 2rem;
					}
				}
			}
		}
		.user_dropdown {
			li {
				> label {
					@include flex(null, space-between);
					input {
						margin-left: 2rem;
					}
				}
			}
		}
		.tag_list {
			@include list;
			@include flex($flow: wrap);
			margin: -0.5rem;
			> li {
				display: block;
				padding: 0.5rem;
				> span {
					@include i_flex(center);
					background: rgba(var(--prime_color_rgb), 0.2);
					color: var(--prime_color);
					padding: 1rem 2rem;
					border-radius: 0.5rem;
					svg {
						width: 1.6rem;
						min-width: 1.6rem;
						height: 1.6rem;
						margin-left: 1rem;
						cursor: pointer;
						&:hover {
							path {
								fill: var(--red);
							}
						}
						path {
							fill: var(--prime_color);
							@include trans;
						}
					}
				}
			}
		}
		.alpha_list {
			@include list;
			@include flex($justify: center, $flow: wrap);
			margin: -0.5rem;
			> li {
				padding: 0.5rem;
				> button {
					width: 4.8rem;
					min-width: 4.8rem;
					height: 4.8rem;
					background: var(--light);
					color: #494949;
					border: 0.1rem solid #494949;
					border-radius: 0.5rem;
					&:hover {
						background: rgba(var(--prime_color_rgb), 0.2);
						border-color: var(--prime_color);
						color: var(--prime_color);
					}
					&.active {
						background: var(--prime_color);
						color: var(--light);
						border-color: var(--prime_color);
					}
				}
			}
		}
	}
	&#lawyer_info_popup {
		.prof_blk {
			@include flex(center);
			margin-bottom: 4rem;
			.ico {
				width: 9rem;
				min-width: 9rem;
				height: 9rem;
				margin-right: 2rem;
			}
		}
		.listing {
			@include list;
			@include flex($flow: wrap);
			margin: -1rem;
			> li {
				padding: 1rem;
			}
		}
		.ytd_blk {
			ul {
				@include list;
				@include flex($flow: wrap);
				margin: -1rem;
				> li {
					width: 50%;
					padding: 1rem;
					&:nth-child(1) {
						.inner {
							background: var(--prime_color);
						}
					}
					&:nth-child(2) {
						.inner {
							background: var(--second_color);
						}
					}
					.inner {
						color: var(--light);
						padding: 1rem 2rem;
						border-radius: 1rem;
					}
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Logon
|----------------------------------------------------------------------
*/

#logon {
	.flex_blk {
		@include flex(center, center);
		min-height: 100vh;
		padding: 10rem 0;
		@include mobile {
			padding: 3rem 0;
		}
	}
	form {
		width: 100%;
	}
	.log_blk {
		position: relative;
		width: 100%;
		max-width: 114rem;
		background: var(--light);
		padding: 6.4rem;
		margin: auto;
		border-radius: 0.5rem;
		@include mobile {
			padding: 3rem;
		}
		> .back_btn {
			@include pos(4rem, null, null, 4rem);
			width: 4.8rem;
			height: 4.8rem;
			&:hover {
				svg {
					path {
						fill: var(--prime_color);
					}
				}
			}
		}
		.inner {
			max-width: 80rem;
			margin: auto;
			font-size: 2.5rem;
			.logo_icon {
				width: 12rem;
				min-width: 12rem;
				height: 12rem;
				margin: 0 auto 1rem;
			}
			.verify_row {
				.input {
					width: 10rem;
					height: 10rem;
					font-size: 5rem;
					font-weight: 700;
					text-align: center;
					
				}
			}
		}
		.forgot_btn {
			a {
				color: var(--prime_color);
				&:hover {
					color: var(--second_color);
				}
			}
		}
		.OR {
			@include flex(center);
			color: #626262;
			margin: 2rem 0;
			gap: 1rem;
			grid-gap: 1rem;
			&:before,
			&:after {
				content: "";
				-webkit-box-flex: 1;
				-ms-flex: 1;
				flex: 1;
				border-top: 0.1rem solid #626262;
			}
			&:before {
				margin-right: 6rem;
			}
			&:after {
				margin-left: 6rem;
			}
		}
		.social_btn_blk {
			.apple_btn {
				&:not(:hover) {
					background: #050708;
				}
			}
			.google_btn {
				&:not(:hover) {
					background: #eb2d3c;
				}
			}
		}
		.already_acc {
			color: #626262;
			margin-top: 2rem;
			> a {
				color: var(--prime_color);
			}
		}
	}
	&.handbook {
		.ico {
			width: 12rem;
			min-width: 12rem;
			height: 12rem;
			margin: 0 auto 6rem;
		}
	}
}

@media (max-width: 425px) {
	.inner h2{
		font-size: 2.8rem;
		margin-top: 1.1rem;
	}

	.back_btn {
		width: 3rem !important;
	}
}

@media (max-width: 375px) {
	.inner h2{
		font-size: 2.3rem;
		margin-top: 1.3rem;
	}

	.back_btn {
		width: 3rem !important;
	}
}
