html {
	min-height: 100%;
	// font-size: 10 + $pix;
	overflow: hidden;
	overflow-y: initial;

	&.flow {
		overflow: hidden !important;

		body {
			overflow: hidden !important;
		}
	}
}

body {
	position: relative;
	@include flex($flow: column);
	min-height: 100vh;
	// background-color: var(--light);
	background-color: #f4f4f4;
	color: var(--dark);
	font-size: 1.8rem;
	font-family: $font;
	font-weight: 400;
	margin: 0;
	padding: 0;
	line-height: 1.6;
	word-break: break-word;
	@include trans;

	@include mobile {
		line-height: 1.4;
	}

	> main {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		// padding-top: 19.2rem;
		// overflow: hidden;
	}

	@at-root {
		section {
			position: relative;
			// padding: 6rem 0;
		}

		a {
			color: var(--black);
			word-break: break-word;
			text-decoration: none;
			@include trans;

			&:hover {
				color: var(--prime_color);
			}

			&:focus,
			&:hover {
				outline: none !important;
				text-decoration: none !important;
			}
		}

		.ease,
		button {
			@include trans;
		}

		.br {
			padding-bottom: 3rem;
		}

		.color {
			color: var(--prime_color);
		}

		.background {
			background: var(--prime_color);
		}

		.color2 {
			color: var(--second_color);
		}

		.background2 {
			background: var(--second_color);
		}

		.alert {
			padding: 1rem 2rem;
			border: 0.1rem solid;
			border-radius: 0.6rem;
		}

		.green {
			background: var(--green);

			&_text {
				color: var(--green);
			}
		}

		.yellow {
			background: var(--yellow);

			&_text {
				color: var(--yellow);
			}
		}

		.red {
			background: var(--red);

			&_text {
				color: var(--red);
			}
		}

		.blue {
			background: var(--blue);

			&_text {
				color: var(--blue);
			}
		}

		.gray {
			background: var(--gray);

			&_text {
				color: var(--gray);
			}
		}

		.theme {
			background: var(--theme);

			&_text {
				color: var(--theme);
			}
		}

		.tooltip_btn {
			position: relative;

			&:hover {
				.tooltip {
					margin-bottom: 1rem;
					opacity: 1;
				}
			}

			.tooltip {
				right: 50%;
				bottom: 100%;
				background: var(--light);
				font-size: 1.6rem;
				font-weight: 500;
				margin-bottom: -2rem;
				padding: 0.8rem 1.5rem;
				text-align: center;
				border: 0.1rem solid var(--light_gray);
				border-radius: 0.5rem;
				pointer-events: none;
				-webkit-transform: translateX(50%);
				-ms-transform: translateX(50%);
				transform: translateX(50%);
				white-space: nowrap;
				-webkit-box-shadow: var(--shadow2);
				box-shadow: var(--shadow2);
				@include trans;
				z-index: 5;

				&:before {
					content: attr(data-text);
				}

				&:after {
					content: "";
					@include pos($t: 100%, $b: null);
					width: 2rem;
					height: 1rem;
					background: inherit;
					margin: auto;
					-webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
					clip-path: polygon(100% 0, 0 0, 50% 100%);
				}
			}
		}
	}
}

::-moz-selection {
	background: var(--prime_color);
	color: var(--light);
}

::selection {
	background: var(--prime_color);
	color: var(--light);
}

h1,
.h1,
.fs_1 {
	font-size: 6rem;
}

h2,
.h2,
.fs_2 {
	font-size: 5rem;

	@include mobile {
		font-size: 4rem;
	}
}

h3,
.h3,
.fs_3 {
	font-size: 3.5rem;

	@include mobile {
		font-size: 3.2rem;
	}
}

h4,
.h4,
.fs_4 {
	font-size: 3rem;

	@include mobile {
		font-size: 2.8rem;
	}
}

h5,
.h5,
.fs_5 {
	font-size: 2.4rem;

	@include mobile {
		font-size: 2.2rem;
	}
}

h6,
.h6,
.fs_6 {
	font-size: 2rem;

	@include mobile {
		font-size: 1.8rem;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: 600;
	line-height: 1.1;
	margin: 0 0 1.6rem;

	@media (max-width: 375px) {
		font-size: 1.7rem !important;
	}

	&.subheading {
		margin-bottom: 2rem;
	}

	&.require {
		&::after {
			content: "*";
			color: var(--red);
		}
	}

	> a {
		color: inherit;
	}

	> strong {
		color: var(--prime_color);
		font-weight: inherit;
	}
}

.fw {
	&_100 {
		font-weight: 100 $i;
	}

	&_200 {
		font-weight: 200 $i;
	}

	&_300 {
		font-weight: 300 $i;
	}

	&_400 {
		font-weight: 400 $i;
	}

	&_500 {
		font-weight: 500 $i;
	}

	&_600 {
		font-weight: 600 $i;
	}

	&_700 {
		font-weight: 700 $i;
	}

	&_800 {
		font-weight: 800 $i;
	}
}

p {
	margin: 0 0 1rem;

	> a {
		display: inline;
		color: var(--prime_color);

		&:hover {
			color: #2115dc;
		}
	}

	&:nth-last-child(1) {
		margin: 0;
	}
}

.table_dv {
	display: table;
	@include fill;

	.table_cell {
		display: table-cell;
		vertical-align: middle;
	}
}

.toggle {
	@include flex(center, center);
	display: none;
	@include pos($l: null, $r: 3rem, $z: 4);
	width: 3rem;
	height: 2.4rem;
	background: transparent;
	padding: 0;
	margin: auto;
	border: 0;
	cursor: pointer;
	@include trans;

	&::before,
	&::after,
	> span {
		position: absolute;
		width: inherit;
		height: 0.3rem;
		background: var(--light);
		@include trans;
	}

	&::before {
		content: "";
		top: 0;

		@at-root .active#{&} {
			top: 50%;
			margin-top: -0.1rem;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	&::after {
		content: "";
		bottom: 0;

		@at-root .active#{&} {
			bottom: 50%;
			margin-bottom: -0.1rem;
			-webkit-transform: rotate(135deg);
			-ms-transform: rotate(135deg);
			transform: rotate(135deg);
		}
	}

	> span {
		@at-root .active#{&} {
			opacity: 0;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Site Button
|----------------------------------------------------------------------
*/

_btn {
	@at-root {
		.site#{&} {
			position: relative;
			@include i_flex(center, center);
			gap: 2rem;
			grid-gap: 2rem;
			height: 6rem;
			background: var(--prime_color);
			color: var(--light);
			font-size: 2rem;
			font-weight: 500;
			text-align: center;
			padding: 0 2.5rem;
			white-space: nowrap;
			border: 0;
			border-radius: 0.5rem;
			outline: none !important;
			// text-transform: uppercase;
			// -webkit-box-shadow: var(--shadow);
			// box-shadow: var(--shadow);
			@include trans;

			&:hover {
				// background: var(--prime_color);
				background: var(--dark);
				color: var(--light);
			}

			> img {
				// width: 3.8rem;
				width: auto;
				height: 2rem;
				-webkit-filter: brightness(0) invert(1);
				filter: brightness(0) invert(1);
				-webkit-transition: inherit;
				transition: inherit;
			}

			> svg {
				width: 2.4rem;
				min-width: 2.4rem;
				height: 2.4rem;
				@include trans;

				path {
					fill: var(--light);
				}
			}

			&.sm {
				height: 4rem;
				font-size: 1.8rem;
				padding: 0 1.8rem;
			}

			&.md {
				height: 4.8rem;
			}

			&.lg {
				height: 5.6rem;
				padding: 0 4rem;
			}

			&.px {
				padding-left: 5rem;
				padding-right: 5rem;
			}

			&.blank {
				background: transparent;
				color: var(--prime_color);

				&:hover {
					background: var(--prime_color);
					color: var(--light);
				}

				&:not(:hover) {
					> img {
						-webkit-filter: none;
						filter: none;
					}
				}

				&.light {
					color: var(--light);
					border: 0.2rem solid var(--light);

					&:hover {
						background: rgba(var(--light_rgb), 0.3);
					}
				}
			}

			&.light {
				background: rgba(var(--prime_color_rgb), 0.1);
				color: var(--prime_color);

				&:not(:hover) {
					-webkit-box-shadow: none;
					box-shadow: none;

					> img {
						-webkit-filter: none;
						filter: none;
					}
				}

				&:hover {
					background: var(--prime_color);
					color: var(--light);
				}
			}

			&.gray {
				background: #f1f0f2;
				color: var(--prime_color);

				> img {
					-webkit-filter: none;
					filter: none;
				}

				&:hover {
					background: var(--prime_color);
					color: var(--light);
				}
			}

			&.text {
				min-width: initial;
				height: auto;
				background: transparent;
				color: var(--prime_color);
				padding: 0;
				border: 0;
				-webkit-box-shadow: none;
				box-shadow: none;

				&:hover {
					background: transparent;
					color: var(--black);
				}

				> img {
					-webkit-filter: none;
					filter: none;
				}
			}

			&.simple {
				background: var(--light);
				color: var(--black);

				&:hover {
					background: var(--light);
					color: var(--prime_color);
				}

				> img {
					-webkit-filter: none;
					filter: none;
				}

				&.stroke:not(:hover) {
					border-color: var(--dark_gray);
				}
			}

			&.dark {
				background: var(--black);
				color: var(--light);

				&:hover {
					background: var(--prime_color);
					color: var(--light);
				}
			}

			&.stroke {
				border: 0.2rem solid var(--prime_color);
			}

			&.round {
				border-radius: 5rem;
			}

			&.block {
				width: 100%;
				// justify-content: space-between;
			}

			&.auto {
				-webkit-box-flex: 1;
				-ms-flex: auto;
				flex: auto;
			}

			&.long {
				min-width: 16rem;
			}

			&.longest {
				min-width: 30rem;
			}

			&.back,
			&.learn {
				display: -webkit-inline-box !important;
				display: -ms-inline-flexbox !important;
				display: inline-flex !important;
				height: auto;
				background: transparent;
				color: var(--black);
				padding: 0;
				-webkit-box-shadow: none;
				box-shadow: none;

				&:hover {
					color: var(--prime_color);

					img {
						-webkit-transform: translateX(0.5rem);
						-ms-transform: translateX(0.5rem);
						transform: translateX(0.5rem);
					}
				}

				> img {
					// width: 2.2rem;
					-webkit-transition: inherit;
					transition: inherit;
					-webkit-filter: none;
					filter: none;
				}
			}

			&.back {
				font-family: $font;

				&:before {
					content: "";
					width: 1.6rem;
					height: 2rem;
					background: var(--black);
					margin-right: -0.5rem;
					-webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
					clip-path: polygon(100% 0, 0 50%, 100% 100%);
				}
			}

			&.mobile_100 {
				@include mobile_sm {
					width: 100%;
				}
			}

			&:disabled {
				opacity: 0.5;
			}
		}

		.link#{&} {
			@include i_flex(center);
			background: transparent;
			padding: 0;
			border: 0;
			text-decoration: underline !important;
			white-space: nowrap;

			&.color {
				color: var(--prime_color);

				&:hover {
					color: var(--black);
				}
			}

			&.plus {
				&::before {
					content: "";
					display: block;
					@include plus;
					width: 2rem;
					height: 2rem;
					margin-right: 2rem;
				}
			}

			&:hover {
				text-decoration: none !important;
			}
		}

		.pop#{&} {
			cursor: pointer;
		}

		.x#{&} {
			@include flex(center, center);
			@include pos(1.5rem, 1.5rem, null, null);
			width: 3.4rem;
			min-width: 3.4rem;
			height: 3.4rem;
			background: var(--black);
			color: var(--light);
			font-size: 1.6rem;
			font-weight: 600;
			padding: 0;
			text-align: center;
			border: 0;
			border-radius: 75%;
			cursor: pointer;
			@include trans;
			z-index: 5;

			&:hover {
				background: var(--prime_color);
			}

			&:before {
				content: "";
				// content: "\2715";
				@include cross;
				width: 40%;
				height: 40%;
				line-height: 1;
			}
		}

		.lbl#{&} {
			@include flex;
			text-align: left;

			> input[type="radio"],
			> input[type="checkbox"] {
				margin-top: 0.1rem;
			}

			label {
				-ms-flex-item-align: center;
				align-self: center;
				line-height: 1.5;
				cursor: pointer;
				margin: 0 0 0 1rem;
			}
		}
	}
}

*[disabled] {
	cursor: not-allowed !important;
}

/*
|----------------------------------------------------------------------
|       Body Inner Css
|----------------------------------------------------------------------
*/

img {
	width: 100%;
	display: block;
}

b,
strong {
	font-weight: 700;
}

input,
button,
select,
textarea {
	text-decoration: none !important;
	outline: none !important;
}

.contain,
.contain-fluid {
	position: relative;
	max-width: $container;
	padding: 0 2rem;
	margin: 0 auto;
	min-height: 0.1rem;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after,
	&:after {
		clear: both;
	}

	&.sm {
		max-width: $container_sm;
	}
}

.contain-fluid {
	max-width: $container_fluid !important;
}

.row {
	@include row;

	&.flex_row {
		> div {
			@include flex;

			> * {
				width: 100%;
			}
		}
	}
}

.btn_blk {
	@include flex($flow: wrap);
	gap: 1.5rem;
	grid-gap: 1.5rem;
}

/*
|----------------------------------------------------------------------
|       Popup
|----------------------------------------------------------------------
*/

.popup {
	@include pos($pos: fixed);
	// display: none;
	// background: rgba(25, 33, 37, 0.95);
	padding: 0;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	@include trans;
	z-index: 101;

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
	}

	> .overlay {
		@include pos($pos: fixed, $z: 1);
		background: rgba(var(--dark_rgb), 0.6);
	}

	> .table_dv {
		@include fill;
		@include flex($justify: center, $flow: wrap);

		> .table_cell {
			display: block;
			-ms-flex-item-align: center;
			align-self: center;
			width: 100%;
			padding: 2rem 0;
		}
	}

	._inner {
		position: relative;
		background: var(--light);
		padding: 4rem 13rem;
		margin: auto;
		border-radius: 1rem;
		z-index: 3;

		@media (max-width: 768px) {
			padding: 3rem 7rem;
			font-size: 1.7rem;
		}

		@media (max-width: 425px) {
			padding: 3rem 5rem 2rem 5rem;
			font-size: 1.2rem;
		}

		@media (max-width: 375px) {
			padding: 3rem 5rem 2rem 5rem;
			font-size: 1.2rem;
		}

		> .back_btn {
			@include pos(2rem, null, null, 2rem);
			width: 4.8rem;
			height: 4.8rem;
			background: transparent;
			padding: 0;
			border: 0;

			&:hover {
				svg {
					path {
						fill: var(--prime_color);
					}
				}
			}
		}

		> .x_btn {
			& + h1,
			& + h2,
			& + h3,
			& + h4,
			& + h5,
			& + h6 {
				padding-right: 3rem;
			}
		}
	}

	.list {
		> li {
			@include flex;
			margin-bottom: 1rem;

			> div:nth-child(1) {
				width: 12rem;
				min-width: 12rem;
				font-weight: 700;
				margin-right: 1rem;
			}

			> div:nth-child(2) {
				width: 100%;
				color: var(--dark_gray);
			}
		}
	}

	hr {
		margin: 2rem 0;
	}
}

/*
|----------------------------------------------------------------------
|       Dropdown
|----------------------------------------------------------------------
*/

.dropdown {
	.dropdown-toggle {
		&:after {
			display: none;
		}

		&.select {
			@include flex(center, space-between);
			gap: 1.5rem;
			grid-gap: 1.5rem;
			overflow: hidden;

			&:after {
				color: inherit;
				margin-left: auto;
			}
		}

		&.chevron {
			@include nowrap;
			padding-right: 4rem;

			&:after {
				content: "";
				@include chev;
				@include pos($l: null, $r: 2rem);
				display: block;
				width: 1.4rem;
				min-width: 1.4rem;
				height: 1.4rem;
				border: 0;
				padding: 0;
				margin: auto;
				-webkit-transition: none;
				transition: none;
			}

			&.show {
				&:after {
					-webkit-transform: rotateX(180deg);
					transform: rotateX(180deg);
				}
			}
		}
	}

	.dropdown-menu {
		// top: 100% !important;
		min-width: hp(2);
		font-size: inherit;
		margin: 0;
		padding: 0.7rem 0;
		border-radius: 0.7rem;
		-webkit-box-shadow: var(--shadow);
		box-shadow: var(--shadow);
		// @include trans;
		// transform: none !important;
		z-index: 5;

		> li {
			display: block;

			&:not(:nth-last-child(1)) {
				border-bottom: 0.1rem solid var(--light_gray);
			}

			> * {
				@include flex(center);
				width: 100%;
				background: var(--light);
				text-align: left;
				line-height: 1.2;
				padding: 1.5rem 2rem;
				border: 0;

				&:hover {
					background: rgba(var(--prime_color_rgb), 0.05);
				}
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Ico Css
|----------------------------------------------------------------------
*/

.ico {
	position: relative;
	@include flex(center, center);
	// background: var(--light);
	text-align: center;

	a,
	img {
		@include object(contain);
	}

	&.round {
		border-radius: 75%;

		img {
			border-radius: inherit;
		}
	}

	&.fill {
		-webkit-box-shadow: var(--shadow);
		box-shadow: var(--shadow);

		img {
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
}

/*
|----------------------------------------------------------------------
|       Form
|----------------------------------------------------------------------
*/

label {
	display: block;
	font-weight: inherit;
	cursor: pointer;

	&.require {
		&::after {
			content: "*";
			color: var(--red);
			font-weight: 700;
			margin-left: 0.4rem;
		}
	}

	> a {
		color: #3c9cdb;

		&:hover {
			color: var(--prime_color);
		}
	}
}

.form_blk {
	position: relative;

	&:not(:nth-last-child(1)) {
		margin-bottom: 2rem;
	}

	&.pass_blk {
		position: relative;

		> .input {
			padding-right: 8rem;
		}

		> i {
			@include pos($l: null, $r: 1.4rem);
			@include flex(center);
			height: 100%;
			font-style: normal;
			cursor: pointer;
			white-space: nowrap;
			text-decoration: underline;
			@include trans;

			&.icon-eye {
				&:before {
					content: "Show";
				}
			}

			&.icon-eye-slash {
				&:before {
					content: "Hide";
				}
			}

			&:hover {
				color: var(--prime_color);
			}
		}
	}

	> span {
		color: var(--red);
		position: absolute;
		top: 100%;
		font-size: 1.1rem;
		font-weight: 500;
	}

	> svg {
		@include pos($l: 2rem, $r: null);
		@include flex(center, center);
		width: 2.4rem;
		height: 2.4rem;
		margin: auto;
		pointer-events: none;

		path {
			fill: #bcb9b9;
			stroke: #bcb9b9;
		}

		& + .input {
			padding-left: 5.4rem;
		}
	}

	&.input {
		@include flex(center);
		height: auto;
		padding-top: 0;
		padding-bottom: 0;

		> .site_btn {
			margin-left: 2rem;
			margin-right: -1.4rem;

			@include ipad_sm {
				margin-right: 2rem;
			}
			@include mobile {
				margin-right: 2rem;
			}
			@include mobile_md {
				margin-right: 2rem;
			}
			@include ipad {
				margin-right: 2rem;
			}
		}

		> .ico_btn {
			width: 2.6rem;
			min-width: 2.6rem;
			height: 2.6rem;
			background: transparent;
			margin-left: 2rem;
			padding: 0;
			border: 0;
		}
	}
}

.upload_blk {
	> button {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	> input[type="file"] {
		display: none;
	}
}

.flex_blk {
	@include flex;

	> div {
		margin: 0;

		&.form_blk {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			margin: 0 !important;
		}

		&.auto {
			-webkit-box-flex: 0;
			-ms-flex: none;
			flex: none;
		}

		&.btn_blk {
			margin-left: 1rem;
		}

		&.data {
			margin-right: 1.5rem;

			> * {
				margin: 0;
			}
		}
	}
}

.input {
	display: block;
	width: 100%;
	height: 6rem;
	background: var(--light);
	font-size: inherit;
	// font-weight: inherit;
	font-weight: 500;
	text-align: left;
	line-height: inherit;
	padding: 0.6rem 2rem;
	border: 0.1rem solid var(--dark_gray);
	border-radius: 0.5rem;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	@include trans;

	&:-webkit-autofill {
		-webkit-transition: background-color 5000s ease-in-out 0s;
		transition: background-color 5000s ease-in-out 0s;
	}

	> .input {
		height: calc(6rem - 0.2rem);
		background: transparent;
		padding: 0;
		border: 0;
	}

	@at-root {
		textarea#{&} {
			height: 20rem;
			padding-top: 1.2rem;
			resize: none;
		}
	}

	&:disabled {
		background: #e3e8ee;
		cursor: not-allowed;
		border-color: #d8dee6 !important;
	}

	&[readonly] {
		background: rgba(var(--prime_color_rgb), 0.05);
		border-color: var(--prime_color);
	}

	&::-webkit-input-placeholder {
		color: rgba(#212121, 0.3);
	}

	&::-moz-placeholder {
		color: rgba(#212121, 0.3);
	}

	&:-ms-input-placeholder {
		color: rgba(#212121, 0.3);
	}

	&::-ms-input-placeholder {
		color: rgba(#212121, 0.3);
	}

	&::placeholder {
		color: rgba(#212121, 0.3);
	}

	&:focus {
		&::-webkit-input-placeholder {
			opacity: 0.4;
		}

		&::-moz-placeholder {
			opacity: 0.4;
		}

		&:-ms-input-placeholder {
			opacity: 0.4;
		}

		&::-ms-input-placeholder {
			opacity: 0.4;
		}

		&::placeholder {
			opacity: 0.4;
		}
	}

	&:hover,
	&:focus {
		border-color: var(--prime_color);
	}

	@at-root {
		select#{&} {
			@include chevron;
			background-position: right 2rem center;
			padding-right: 4rem !important;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
	}

	&.error {
		border-color: var(--red);
	}
}

.form-floating {
	> .input {
		&:not(:placeholder-shown) {
			& ~ label {
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}

		&:focus {
			& + label {
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}

			&::-webkit-input-placeholder {
				opacity: 1;
			}

			&::-moz-placeholder {
				opacity: 1;
			}

			&:-ms-input-placeholder {
				opacity: 1;
			}

			&::-ms-input-placeholder {
				opacity: 1;
			}

			&::placeholder {
				opacity: 1;
			}
		}

		&:not(:focus) {
			&::-webkit-input-placeholder {
				opacity: 0;
			}

			&::-moz-placeholder {
				opacity: 0;
			}

			&:-ms-input-placeholder {
				opacity: 0;
			}

			&::-ms-input-placeholder {
				opacity: 0;
			}

			&::placeholder {
				opacity: 0;
			}
		}
	}

	> label {
		height: auto;
		color: #a5a5a4;
		line-height: 1;
		padding: 0.6rem 0.4rem;
		margin: auto 1.6rem;
		border: 0;
		-webkit-transform: translateY(50%);
		-ms-transform: translateY(50%);
		transform: translateY(50%);

		&::before {
			content: "";
			@include pos($t: 50%, $z: -1);
			background: var(--light);
		}
	}
}

input {
	&[type="radio"],
	&[type="checkbox"] {
		position: relative;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 2rem;
		min-width: 2rem;
		height: 2rem;
		background: var(--light);
		color: var(--light);
		margin-top: 0;
		border: 0.1rem solid var(--gray);
		cursor: pointer;
	}

	// &[type="radio"] {
	// 	padding: 0.3rem;
	// 	border-radius: 75%;
	// 	&:checked {
	// 		background: var(--prime_color);
	// 		background-clip: content-box;
	// 		border-color: var(--prime_color);
	// 	}
	// }
	&[type="radio"],
	&[type="checkbox"] {
		border-radius: 0.4rem;

		&:before {
			content: "";
			@include pos($z: 1);
			@include tick;
			width: 0.8rem;
			height: 0.8rem;
			margin: auto;
		}

		&:checked {
			background: var(--prime_color);
			border-color: var(--prime_color);

			& + .checkbox {
				background: var(--prime_color);
				border-color: var(--prime_color);
			}
		}
	}

	&[type="file"].uploadFile {
		display: none;
	}

	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}

	@at-root {
		.checkbox {
			@extend input, [type="checkbox"];
			-webkit-transition: all ease 0.2s;
			transition: all ease 0.2s;
		}
	}
}

.badge,
.label {
	color: var(--light);
	font-size: inherit;
	font-weight: 500;
	padding: 0.4rem 0.7rem;
	line-height: 1;
	text-align: center;
	border-radius: 0.5rem;
	white-space: nowrap;
}

/*
|----------------------------------------------------------------------
|       Scrollbar
|----------------------------------------------------------------------
*/

.scrollbar {
	&::-webkit-scrollbar-track {
		background: #f6f9fc;
		border-radius: 5rem;
	}

	&::-webkit-scrollbar {
		width: 0.5rem;
		height: 1rem;
		background-color: #f6f9fc;
		border-radius: 5rem;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(var(--black_rgb), 0.15);
		border-radius: 5rem;
	}
}

/*_____ top_head _____*/

.top_head {
	@include flex(center, space-between);
	margin-bottom: 2rem;
	gap: 1.5rem 1rem;
	grid-gap: 1.5rem 1rem;

	@include mobile_md {
		-webkit-box-align: initial;
		-ms-flex-align: initial;
		align-items: initial;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-flow: column;
		flex-flow: column;
	}

	> .miniBtn {
		@include flex(center);

		select {
			width: auto;
			max-width: 12rem;
			height: auto;
			background-color: transparent;
			background-position: right;
			border: 0;
		}
	}

	> .heading {
		margin: 0;
	}
}

/*
|----------------------------------------------------------------------
|       Tab List
|----------------------------------------------------------------------
*/

.tab_list {
	border-bottom: 0.07rem solid rgba(29, 29, 27, 20%);
	margin: 0 0 4rem;

	@include mobile {
		@include flex($flow: nowrap);
		-webkit-box-pack: initial !important;
		-ms-flex-pack: initial !important;
		justify-content: initial !important;
		margin-left: -3rem;
		margin-right: -3rem;
		padding: 0 3rem;
		border-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;

		&::-webkit-scrollbar {
			height: 0;
		}
	}

	// @include mobile_sm {
	// margin-left: -1rem;
	// margin-right: -1rem;
	// border-bottom: 0;
	// }
	> li {
		padding: 0 1rem;

		@include mobile {
			padding: 0;
		}

		// @include mobile_sm {
		// 	width: 100%;
		// }
		> a {
			display: block;
			min-width: 24rem;
			background: rgba(52, 50, 73, 7%);
			padding: 2rem;
			text-align: center;
			text-transform: uppercase;
			line-height: 1;
			border-radius: 2rem 2rem 0 0;

			@include mobile {
				min-width: initial;
				padding-right: 4rem;
				border-radius: 1rem 10rem 0 0;
			}

			// @include mobile_sm {
			// 	border-radius: 0;
			// 	margin-bottom: 0.1rem;
			// }
			&.active {
				background: var(--black);
				color: var(--light);
			}
		}
	}
}

/*
|----------------------------------------------------------------------
|       Switcher
|----------------------------------------------------------------------
*/

.switch {
	position: relative;
	width: 6rem;
	min-width: 6rem;
	height: 3rem;
	cursor: pointer;

	* {
		@include trans;
	}

	input[type="radio"],
	input[type="checkbox"] {
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
		height: 100% !important;
		margin: 0 !important;
		border: 0 !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		opacity: 0;
		z-index: 5;

		&:before,
		&:after {
			display: none;
		}

		&:checked + em:before {
			-webkit-transform: translateX(1.5rem);
			-ms-transform: translateX(1.5rem);
			transform: translateX(1.5rem);
		}

		&:checked + em:after {
			background: var(--prime_color);
		}
	}

	> em,
	> em:before,
	> em:after {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: transparent;
		padding: 0;
		z-index: 1;
	}

	> em {
		@include flex(center);
		border: none;

		&:before {
			content: "";
			width: 2rem;
			height: 2rem;
			background: var(--light);
			border-radius: 75%;
			-webkit-transform: translateX(-1.5rem);
			-ms-transform: translateX(-1.5rem);
			transform: translateX(-1.5rem);
			-webkit-transition: all linear 0.3s;
			transition: all linear 0.3s;
			z-index: 5;
		}

		&:after {
			content: "";
			// background: var(--light_gray);
			background: #d2d2d1;
			border-radius: 5rem;
			-webkit-transition: all linear 0.3s;
			transition: all linear 0.3s;
		}
	}
}

.firm_detail_container {
	display: flex;
}

.firm_info_container {
	margin-left: 5rem;
}

.firm_lawyer_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 5rem;
}

.btn_container {
	display: flex;
	justify-content: space-between;
	width: 88rem;
	@include mobile {
		flex-direction: column;
		width: 38rem !important;
	}
	@include mobile_md {
		flex-direction: column;
		width: 38rem;
	}
	@include ipad_sm {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 30rem;
		width: auto;
	}
	@include mobile_xs {
		flex-direction: column;
		width: 34rem !important;
	}
}

.top_bar_container {
	@include ipad_sm {
		display: "flex";
		flex-direction: column;
	}
}

.termsAndConditionsContainer {
	margin-top: 12rem;
	width: 90rem;
	height: 70rem;
	background-color: #ffffff;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	::-webkit-scrollbar-thumb {
		background: #63a744;
		border-radius: 10px;
	}

	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #b2e49b;
		border-radius: 0.5rem;
	}
}

.termsAndConditionText {
	text-align: center;
	font-size: 2.5rem;
	margin-top: 6rem;
	font-weight: 600;
}

.termsAndConditionContent {
	margin-top: 2rem;
	width: 80rem;
	height: 45rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	overflow: scroll;
	overflow-x: hidden;
}

.text {
	padding: 0rem 3rem 3rem 3rem;
	font-size: 1.5rem !important;
}

.checkBoxContainer {
	margin-top: 5rem;
	margin-left: 3rem;
	display: flex;
	justify-content: space-between;
	width: 80rem;
}

.checkboxText {
	font-weight: bold;
	cursor: pointer;
}

.continueButton {
	margin-top: 0.3rem;
	background-color: #63a744;
	width: 15rem;
	height: 5rem;
	border-radius: 1rem;
	text-align: center;
	color: #ffffff;
	line-height: 4.7rem;
	cursor: pointer;
}

.continueButton:hover {
	margin-top: 0.3rem;
	background-color: #63a744;
	width: 15rem;
	height: 5rem;
	border-radius: 1rem;
	text-align: center;
	color: #ffffff;
	line-height: 4.7rem;
	cursor: pointer;
	transform: scale(0.9, 0.9);
	-webkit-transform: scale(0.9, 0.9);
	-moz-transform: scale(0.9, 0.9);
}

.overlayNotifications {
	@include pos($pos: fixed);
	// display: none;
	// background: rgba(25, 33, 37, 0.95);
	padding: 0;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	@include trans;
	z-index: 101;

	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
	}

	> .overlay {
		@include pos($pos: fixed, $z: 1);
		background: rgba(var(--dark_rgb), 0.6);
	}
}

.custom-checkbox-root .MuiSvgIcon-root {
	width: 3rem;
	height: 3rem;
}
.dropdown .dropdown-menu {
	min-width: 15rem;
	font-size: inherit;
	margin: 0;
	padding: 0.7rem 0;
	border-radius: 0.7rem;
	box-shadow: var(--shadow);
	z-index: 5;
}

@media (max-width: 768px) {
	.dropdown .dropdown-menu {
		margin-left: -75%;
		min-width: 5rem;
	}
}

@media (max-width: 768px) {

	.termsAndConditionsContainer {
		margin-top: 3rem;
		width: 50rem;
		border-radius: 1rem;
	}

	.termsAndConditionContent {
		width: 85%;
	}

	.checkBoxContainer {
		margin-top: 5rem;
		margin-left: 0rem;
		width: 80%;
		font-size: 1.3rem;
	}

	.continueButton {
		margin-top: 0.3rem;
		width: 12rem;
		height: 4.5rem;
	}
	
	.checkboxText {
		margin-left: -3rem;
	}
}

@media (max-width: 768px) {

	.termsAndConditionsContainer {
		margin-top: 3rem;
		width: 40rem;
		border-radius: 1rem;
	}

	.termsAndConditionContent {
		width: 85%;
	}

	.checkBoxContainer {
		margin-top: 5rem;
		margin-left: 0rem;
		width: 90%;
		font-size: 1.3rem;
	}

	.continueButton {
		margin-top: 0.3rem;
		width: 10rem;
		height: 4.5rem;
	}
	
	.checkboxText {
		margin-left: -3rem;
	}
}

@media (max-width: 375px) {

	.termsAndConditionsContainer {
		margin-top: 3rem;
		width: 35rem;
		border-radius: 1rem;
	}

	.termsAndConditionContent {
		width: 85%;
	}

	.checkBoxContainer {
		margin-top: 2rem;
		margin-left: 0rem;
		width: 94%;
		font-size: 1.3rem;
	}

	.continueButton {
		margin-top: 0.3rem;
		width: 7rem;
		height: 4.5rem;
	}
	
	.checkboxText {
		margin-left: -3rem;
	}
}

@media (max-width: 320px) {
	.dropdown .dropdown-menu {
		margin-left: -150%;
		min-width: 5rem;
	}

	.termsAndConditionsContainer {
		width: 30rem;
		border-radius: 1rem;
	}

	.termsAndConditionContent {
		width: 90%;
	}

	.checkBoxContainer {
		margin-top: 2rem;
		margin-left: 0rem;
		width: 96%;
		font-size: 1rem;
	}

	.continueButton {
		margin-top: 0.3rem;
		width: 7rem;
		height: 4.5rem;
	}
	
	.checkboxText {
		margin-left: -3rem;
	}
}

.password_wrapper {
	display: flex;
	border: 0.1rem solid var(--dark_gray);
	border-radius: 0.5rem;
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	@include trans;

	&:-webkit-autofill {
		-webkit-transition: background-color 5000s ease-in-out 0s;
		transition: background-color 5000s ease-in-out 0s;
	}

	justify-content: center;
	align-items: center;

	div {
		cursor: pointer;
		img {
			top: 0;
			bottom: 0;
			right: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			-webkit-box-orient: vertical;
			width: 4.4rem;
			height: 4.4rem;
			pointer-events: none;
			z-index: 9999;
			cursor: pointer;
		}
	} 
}

._innerChat {
	position: relative;
	background: var(--light);
	padding: 2rem 5rem;
	margin: auto;
	border-radius: 1rem;
	z-index: 3;

	@media (max-width: 768px) {
		padding: 3rem 7rem;
		font-size: 1.7rem;
	}

	@media (max-width: 425px) {
		padding: 3rem 5rem 2rem 5rem;
		font-size: 1.2rem;
	}

	@media (max-width: 375px) {
		padding: 3rem 5rem 2rem 5rem;
		font-size: 1.2rem;
	}

	> .back_btn {
		@include pos(2rem, null, null, 2rem);
		width: 4.8rem;
		height: 4.8rem;
		background: transparent;
		padding: 0;
		border: 0;

		&:hover {
			svg {
				path {
					fill: var(--prime_color);
				}
			}
		}
	}

	> .x_btn {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			padding-right: 3rem;
		}
	}
}
