#eventDetails {
    .details_blk {
        background: #ffffff;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 3.2rem;
        margin: 4rem 0;
        .bg_blk {
            background-position: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: 30rem;
        }
        .firm-event-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
            width: 75%;

            @media (max-width: 580px) {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
            }

            .event-by {
                h3 {
                    margin-bottom: 2.5rem;
                }
                @media (max-width: 580px) {
                    margin-bottom: 3rem;
                }
            }
            .info {
                h5 {
                    margin-bottom: 1rem;
                }
                .location {
                    display: flex;
                    align-items: center;
                    .location_icon {
                        width: 2.8rem;
                        min-width: 2.8rem;
                        height: 2.8rem;
                        margin-right: 2rem;

                        img {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
        .firm-event-details {
            margin-top: 2rem;
            p {
                font-size: 2rem;
                line-height: 2.5rem;
                color: #7c7c82;
            }
        }
        .register_btn {
            margin: 0 auto;
            margin-top: 6rem;
            min-width: 50rem;
            width: 50rem;
            display: flex;
            @media (max-width: 580px) {
                min-width: 30rem;
                width: 30rem;
            }
        }
    }
}
