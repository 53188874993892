#manage_lawyers {
	.top_blk {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 2rem;
		@media (max-width: 580px) {
			flex-direction: column;
			align-items: start;
		}
		button {
			width: 27rem;
			min-width: 27rem;
			@media (max-width: 580px) {
				margin-top: 2rem;
			}
		}
	}
	.empty_lawyer_text {
		text-align: center;
		margin-top: 30rem;
		vertical-align: middle;
		font-size: large;
		color: #114D96;
	}
}
