@import "../../assets/scss/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

.card {
	padding: 2rem;
	border-radius: 2rem;
	color: $prime_color;
	display: flex;
	flex-direction: column;
	.date {
		margin-top: 2rem;
		font-weight: 600;
		font-size: 2rem;
	}
	.card_title {
		font-size: 3rem;
		font-weight: 600;
		color: $parrot_green;
	}
	.card_description {
		margin: 2rem 0;
		font-weight: 500;
		font-size: 2.1rem;
	}
	.more {
		cursor: pointer;
		font-size: 3rem;
		font-weight: 600;
		align-self: flex-end;
	}
}

@media (max-width: 425px) {
	.card {
		padding: 1rem;
		border-radius: 1.3rem;
		.date {
			margin-top: 1rem;
			font-size: 1.2rem;
		}
		.card_title {
			font-size: 1.6rem;
		}
		.card_description {
			margin: 1rem 0;
			font-size: 1.2rem;
		}
		.more {
			font-size: 1.6rem;
		}
	}
}
