.lawyer_card {
	position: relative;
	border: 0.2rem solid transparent;
	border-radius: 1rem;
	margin-bottom: 2rem;
	width: 100%;
	white-space: nowrap;
	padding: 2.2rem 4.8rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: $white_color;
	-webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		border-color: $prime_color;
		-webkit-transition: all ease-in-out 0.3s;
		transition: all ease-in-out 0.3s;
	}

	@media (max-width: 991px) {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		padding: 2rem 2rem;
	}

	>.pop_btn {
		@include pos($z: 1);
	}

	h5 {
		margin-bottom: 1rem;
	}

	p {
		font-size: 2rem;
		line-height: 3rem;
	}

	&.price_blk {
		margin-right: 4rem;
	}

	.profile_blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-width: 35.4rem;
		width: 31.4rem;
		margin-top: 0;

		@media (max-width: 991px) {
			margin-bottom: 2rem;
		}

		.img_blk {
			width: 7.2rem;
			min-width: 7.2rem;
			height: 7.2rem;
		}

		// .text_blk {
		// 	margin-left: 5rem;
		// }
	}

	.phone {
		min-width: 14.4rem;
		width: 14.5rem;
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;

			display: -webkit-box;

			display: -ms-flexbox;

			display: none;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
		}

		@media (max-width: 580px) {
			display: none;			
		}
	}

	.email {
		min-width: 32rem;
		width: 32rem;
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;
			display: none;
			margin-right: 0;
		}
	}

	.toggle1 {
		min-width: 24rem;
		width: 24rem;
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 580px) {
			width: 100%;
			margin-bottom: 2rem;
		}

		.switch {
			width: 20rem;
			min-width: 20rem;
			height: 6rem;

			input {
				&:checked {
					&+em {
						&:before {
							-webkit-transform: translateX(6.5rem);
							-ms-transform: translateX(6.5rem);
							transform: translateX(6.5rem);
						}

						&:after {
							background: var(--second_color);
						}
					}

					&~span {
						text-align: left;
						padding: 0 3.5rem;
						font-size: 20px;

						&:after {
							content: "Visible";
						}
					}
				}
			}

			>em {
				&:before {
					-webkit-transform: translateX(-6.5rem);
					-ms-transform: translateX(-6.5rem);
					transform: translateX(-6.5rem);
					width: 4rem;
					height: 4rem;
				}

				&:after {
					background: #494949;
				}
			}

			span {
				@include pos($z: 1);
				@include flex(center);
				text-align: right;
				padding: 0 3.5rem;
				font-size: 16px;

				&:after {
					content: "Invisible";
					width: 100%;
					color: var(--light);
				}
			}
		}
	}

	.toggle2 {
		margin-right: 5rem;

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 580px) {
			width: 100%;
		}

		.switch {
			height: 6rem;
			width: 10rem;

			input {
				&:checked {
					&~span {
						&:after {
							content: "Active";
						}
					}
				}
			}

			>em {
				&:before {
					width: 4rem;
					height: 4rem;
				}

				&:after {
					background: #494949;
				}
			}

			span {
				&:after {
					position: absolute;
					top: 100%;
					content: "De-active";
				}
			}
		}
	}

	.switch {
		position: relative;
		margin-bottom: 3rem;
		z-index: 2;
	}

	.phone_email {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		@media (max-width: 991px) {
			width: 100%;
			margin-bottom: 2rem;
		}

		@media (max-width: 580px) {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-ms-flex-align: start;
			align-items: start;
		}
	}

	.mob_toggle_blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;

		@media (max-width: 580px) {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: start;
			-ms-flex-align: start;
			align-items: start;
		}
	}
}

.lawyer_firm {
	justify-content: space-between;
}

.flex_row {
	display: flex;
	flex-direction: row !important;
}

.lawyer_contact {
	align-self: center;
	width: 60rem;
	height: 30rem;
	background-color: aliceblue;
}
