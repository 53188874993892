.floating {
    display: flex;
    margin-left: 30px;
}
.leftMessage {
    background-color:darkgrey;
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
}
.rightMessage {
    background-color:lightgrey;
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    text-align:right;
}

._innerChatBoxDiv{
	padding-left: 1rem;
	padding-right: 1rem;
    height: 40vh;
	max-height: 40vh;

	overflow-y: auto; 	
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}
._innerChatBoxDiv::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

