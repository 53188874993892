#chatBox {
    .chatBox_outer {
		background-color: $white_color;
		border-radius: 1rem;    
		margin-top: 2rem;
        overflow: hidden;

        .row{
            height: 60vh;
            width: 100%;

            .col-md-4{
                height: 100%;
            }
            .col-md-8{
                height: 100%;
            }
        }

        .left_blk_chat {
            padding:2rem;
            height: 100%;
            width: 100%;
            position:relative;
            
            
             display: -webkit-box;
             display: -ms-flexbox;
             display: flex;
             flex-direction: column;
             -webkit-box-align: center;
             -ms-flex-align: center;
             align-items: flex-start;
             background-color:#F9F9F9;

             @media (max-width: 768px) {
                 -webkit-box-orient: vertical;
                 -webkit-box-direction: normal;
                 -ms-flex-direction: column;
                 flex-direction: column;
                 -webkit-box-align: center;
                 -ms-flex-align: center;
                 align-items: center;
                 -webkit-box-pack: center;
                 -ms-flex-pack: center;
                 justify-content: center;
                 margin-bottom: 3rem;
                 
                 ;
             }
             
            
             .search_blk_chat {
            
                display: flex;
                position: relative;
                align-items: center;
                border-radius: 35rem;
                height: 6rem;
                overflow:hidden;
                width: 100%;
                background-color: white;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-top: 2rem;

                @media (max-width: 991px) {
                    width: 100%;
                }
    
                @media (max-width: 425px) {
                    min-width: 10rem !important;
                }
                input[type="search"] {
                    outline: none;
                    width: 100%;
                    border: none;
                    font-size: 2rem;
                    line-height: 3rem;
                   
                }
                ::placeholder{
                    font-size:medium;
                }
                .input-field {
                    display: block;
                    padding-left: 0;
                    padding-bottom: 1rem;
                }
                .img_blk {
                    width: 4.0rem;
                    min-width: 4.0rem;
                    height: 4.0rem;
    
                    img {
                        width: 100%;
                        display: block;
                    }
    
    
                @media (max-width: 991px) {
                    width: 1rem;
                    
                  
                }
    
                @media (max-width: 425px) {
                    width: 1rem !important;
                }
                }
                input {
                    border: initial;
                    width: 100%;
                    padding: 3px 1rem;
                    padding-top: 5px;
                    background-color: inherit;
                }
            }
            .contacts_blk_top{
                padding: 0.5rem;
                margin-top: 1rem;
                width: 100%;
            }
            .contacts_blk{
                padding: 0.5rem;
                margin-top: 1rem;
                width: 100%;
                height: 100%;
                overflow:scroll
            }

         }


         .right_blk_chat{

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;


            .top_blk {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1rem;
               
                border-bottom: 1px solid lightgrey;
                width: 100%;
                height:auto;
    
                
                .content-block{
                    display: flex;
                    align-items: center;
                    
                    padding: 0.5rem;
                    overflow: hidden;

                    .img_blk {
                        img {
                            width: 8rem;
                            height: 8rem;
                            border-radius: 50%;
                        }
                    }

                    .text_blk {
                        display: flex;
                        flex-direction: column;
                        margin-left: 2rem;
        
                        p {
                            font-weight: 500;
                            font-size: 2.4rem;
                            line-height: 3.6rem;
        
                            &:nth-child(2) {
                                font-weight: 400;
                                font-size: 2rem;
                                line-height: 3rem;
                            }
                        }
                    }
                }

                 .content-block{
                    display: flex;
                    align-items: center;
                    
                    padding: 0.5rem;
                    overflow: hidden;

                    .img_blk {
                        img {
                            width: 8rem;
                            height: 8rem;
                            border-radius: 50%;
                        }
                    }

                    .text_blk {
                        display: flex;
                        flex-direction: column;
                        margin-left: 2rem;
        
                       
                        p {
                            font-weight: 500;
                            font-size: 2.4rem;
                            line-height: 3.6rem;
        
                            &:nth-child(2) {
                                font-weight: 400;
                                font-size: 2rem;
                                line-height: 3rem;
                            }
                        }
                    }
                }
                
    
                
            }

            .chat_area{
                display: flex !important;
                flex-direction: column;
                height: 80%;
                overflow:scroll;
                width: 100%;
                padding : 2rem;

                .start_conversation_btn{
                    background-color: #114d96;
                    font-size: x-small;
                }

                .senderMessage{
                    border: 1px solid lightgrey;
                    padding:1.5rem 2rem;
                    margin-left: auto;
                    border-radius: 1rem;
                    width: fit-content;
                    max-width: 96%;
                    margin-top: 1rem;
                    justify-content: flex-end;
                    background-color:#b7d0ef;
                }
                .receiverMessage{
                    border: 1px solid lightgrey;
                    padding:1.5rem 2rem;
                    margin-right: auto;
                    margin-top: 1rem;
                    border-radius: 1rem;
                    width: fit-content;
                    max-width: 96%;
                    justify-content: flex-start;
                    background-color:#f4f2d0;
                }
            }

            .bottom_blk{
               
                height: auto;
                padding: 2rem;
                width: 100%;
                border-top: 1px solid lightgrey;

                  
             .new_message_block {
            
                display: flex;
                position: relative;
                align-items: center;
                border-radius: 35rem;
                height: 6rem;
                overflow:hidden;
                width: 100%;				
                background-color:#F9F9F9;
                
                @media (max-width: 991px) {
                    width: 100%;
                }
    
                @media (max-width: 425px) {
                    min-width: 10rem !important;
                }
                input[type="text"] {
                    outline: none;
                    border: none;
                    font-size: 2rem;
                    line-height: 3rem;
                   
                }
                ::placeholder{
                    font-size:medium;
                }
                .input-field {
                    display: block;
                    padding-left: 0;
                    padding-bottom: 1rem;
                }
               
            
                input {
                    border: initial;
                    width: 100%;
                    padding: 3px 1rem;
                    padding-top: 5px;
                    background-color: inherit;
                }
            }
            }
         }
     
	
	}
}

.tooltip-inner{
    background-color:transparent
}

.search_blk_chat {
            
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 35rem;
    height: 6rem;
    overflow:hidden;
    width: 100%;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;

    @media (max-width: 991px) {
        width: 100%;
    }

    @media (max-width: 425px) {
        min-width: 10rem !important;
    }
    input[type="search"] {
        outline: none;
        width: 100%;
        border: none;
        font-size: 2rem;
        line-height: 3rem;
       
    }
    ::placeholder{
        font-size:medium;
    }
    .input-field {
        display: block;
        padding-left: 0;
        padding-bottom: 1rem;
    }
    .img_blk {
        width: 4.0rem;
        min-width: 4.0rem;
        height: 4.0rem;

        img {
            width: 100%;
            display: block;
        }


    @media (max-width: 991px) {
        width: 1rem;
        
      
    }

    @media (max-width: 425px) {
        width: 1rem !important;
    }
    }
    input {
        border: initial;
        width: 100%;
        padding: 3px 1rem;
        padding-top: 5px;
        background-color: inherit;
    }
}