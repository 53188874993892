@import "../../../assets/scss/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

html {
	font-size: 10px;
	font-family: "Poppins", sans-serif;
}

@media (max-width: 2560px) {
	/* html {
		font-size: 13.333333333333332px;
	} */
}

@media (max-width: 1920px) {
	html {
		font-size: 10px;
	}
}

@media (max-width: 1680px) {
	html {
		font-size: 8.75px;
	}
}

@media (max-width: 1600px) {
	html {
		font-size: 8.333333333333334px;
	}
}

@media (max-width: 1440px) {
	html {
		font-size: 7.5px;
	}
}

@media (max-width: 1400px) {
	html {
		font-size: 7.291666666666666px;
	}
}

@media (max-width: 1368px) {
	html {
		font-size: 7.125px;
	}
}

@media (max-width: 1366px) {
	html {
		font-size: 7.114583333333333px;
	}
}

@media (max-width: 1300px) {
	html {
		font-size: 6.770833333333334px;
	}
}

@media (max-width: 1280px) {
	html {
		font-size: 6.666666666666666px;
	}
}

@media (max-width: 1208px) {
	html {
		font-size: 6.291666666666666px;
	}
}

@media (max-width: 1152px) {
	html {
		font-size: 6px;
	}
}

@media (max-width: 1024px) {
	html {
		font-size: 5.333333333333333px;
	}
}

@media (max-width: 991px) {
	html {
		font-size: 10px;
	}
}
body {
	font-family: "Poppins", sans-serif;
	margin: 0;
	font-weight: 400;
	color: $black_color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
	width: 0;
}

/* a {
    transition: var(--trans);
} */

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
	font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

h1 {
	font-size: 5rem;
}

h2 {
	font-size: 4rem;
}

h3 {
	font-size: 2.8rem;
}

h4 {
	font-size: 2.4rem;
}

h5 {
	font-size: 2rem;
}

h6 {
	font-size: 1.8rem;
}

.fw_300 {
	font-weight: 300;
}

.fw_400 {
	font-weight: 400;
}

.fw_500 {
	font-weight: 500;
}

.fw_600 {
	font-weight: 600;
}

.fw_700 {
	font-weight: 700;
}

.contain {
	max-width: 176rem;
	padding: 0 2rem;
	margin: 0 auto;
}

#home {
	background-color: white;
	.top_bar {
		background-color: $parrot_green;
		/* height: 6rem; */
		padding: 1.8rem 0;
		ul {
			margin-bottom: 0;
			padding-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: end;
			width: 100%;
			list-style-type: none;
			padding-left: 0;

			li {
				margin: 0 1.2rem;
			}
		}
	}
	.header_blk {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 13rem;
		> div {
			width: 24.3rem;
			height: 9.2rem;
		}
		ul {
			display: flex;
			align-items: center;
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;

			li {
				padding: 0 2rem;

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				button {
					width: 17.6rem;
					height: 7.5rem;
					background: #114d96;
					border-radius: 0.5rem;
					font-weight: 600;
					font-size: 2rem;
					line-height: 3rem;
					color: $white_color;
					border: transparent;
				}
				a {
					text-decoration: initial;
					font-weight: 500;
					font-size: 2.4rem;
					line-height: 3.6rem;
				}
			}
		}
	}
	.heading {
		font-size: 5rem;
		font-weight: 500;
		margin-bottom: 3.4rem;
	}
	.text {
		font-size: 2.4rem;
	}
	.image {
		width: 100%;
	}

	.flex_1 {
		flex: 1;
	}
	.access_button {
		cursor: pointer;
		color: $white_color;
		width: fit-content;
		padding: 2.5rem 6rem;
		font-size: 2rem;
		border-radius: 0.6rem;
	}

	.main {
		.feature_img {
			background-color: rgba(0, 0, 0, 0.35);
			background-image: url("../../../assets/images/hero_bg.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-blend-mode: overlay;

			.content_blk {
				min-height: 88.9rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				// max-width: 117rem;
				width: 80%;
				margin: 0 auto;
				color: $white_color;
				.text {
					text-align: center;
					font-size: 4rem !important;

					@media (max-width: 768px) {
						font-size: 2rem !important;
					}

					@media (max-width: 425px) {
						font-size: 1.5rem !important;
					}
				}

				.heading {
					text-align: center;
				}
			}
		}

		.content {
			padding: 5rem 10rem 0rem 10rem;
			.handbook_container {
				display: flex;
				align-items: center;
				.handbook_text_container {
					.heading {
						color: $prime_color;
					}
					.text {
						margin-bottom: 3.4rem;
					}
					.access_button {
						background-color: $prime_color;
					}
				}
			}
			.video_section {
				margin: 5rem 0rem;
				border-radius: 5rem;
				.video {
					width: 100%;
				}
			}
			.download_app {
				display: flex;
				align-items: center;
				.download_buttons {
					.download_links {
						margin-top: 3.4rem;
						display: flex;
						width: 80%;
						justify-content: space-between;
						.image {
							cursor: pointer;
							width: 48%;
						}
					}
				}
			}
		}
		.feedback {
			background-image: url("../../../assets/images/submitBg.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-blend-mode: overlay;
			padding: 10rem 7rem 5rem 7rem;
			display: flex;
			flex-direction: column;

			.submit_question {
				display: flex;
				color: $white_color;
				align-items: center;
				.submit_text {
					padding-left: 10rem;
					.selection_section {
						.block {
							display: flex;
							margin: 1.5rem 0;
							.select_input {
								width: 80%;
								background-color: inherit;
								border: 2px solid $white_color;
								padding: 0.8rem;
								color: $white_color;
							}
						}
					}
					.submit_button {
						cursor: pointer;
						width: fit-content;
						padding: 2.5rem 6rem;
						font-size: 2rem;
						border-radius: 0.6rem;
						background-color: $parrot_green;
					}
				}
			}
			.podcast {
				margin-top: 7rem;
				.heading {
					text-align: center;
					color: $white_color;
					font-weight: 600;
				}
				.cards {
					display: flex;
					justify-content: space-between;
					.card_container {
						width: 32%;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.content {
		padding: 5rem 5rem 0 5rem !important;
	}
	.heading {
		font-size: 3rem !important;
	}
	.text {
		font-size: 1.6rem !important;
	}

	.handbook_container {
		flex-direction: column-reverse;
		.handbook_text_container {
			margin-top: 5rem;

			.access_button {
				padding: 1rem 2rem !important;
				font-size: 1.6rem !important;
				border-radius: 0.3rem !important;
			}
		}
	}
	.download_app {
		.download_buttons {
			.heading {
				margin-bottom: 1rem !important;
			}
			.download_links {
				margin-top: 1rem !important;
			}
		}
	}

	.submit_question {
		flex-direction: column;

		.submit_text {
			margin-top: 5rem;
			padding-left: 0rem !important;
			width: 100%;

			.submit_button {
				padding: 1rem 3rem !important;
				font-size: 1.6rem !important;
				border-radius: 0.3rem !important;
			}
		}
	}
	.cards {
		flex-direction: column;
		align-items: center;
		.card_container {
			width: 80% !important;
			margin: 3rem;
		}
	}
}

@media (max-width: 425px) {
	.main {
		.feature_img {
			.content_blk {
				min-height: 60rem !important;
			}
		}
		.content {
			padding: 2rem 2rem 0 2rem !important;
			.download_app {
				flex-direction: column-reverse;

				.download_buttons {
					margin-bottom: 2rem;
				}
			}
		}
		.feedback {
			padding: 5rem 2rem 2rem 2rem !important;
			.block {
				flex-direction: column;
				.text {
					margin-bottom: 1rem;
				}
				.select_input {
					font-size: 1.4rem !important;
					padding: 0.4rem !important;
				}
			}
		}

		.card_container {
			margin: 1.5rem 0 !important;
			width: 100% !important;
		}
	}
}
