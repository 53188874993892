#notificationCard {
    #top_blk {
        margin-bottom: 8rem;
    }

    .notification_card {
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        cursor: pointer;
        overflow-y: hidden;
        border-bottom: 1px solid #d5d5d5;

        .left_blk {
            padding: 1.8rem;
            flex-direction: row;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 1.8rem;
                margin: 0 !important;
                line-height: 3rem !important;
            }

            span {
                line-height: 4rem;
                color: #898686;
            }
        }

        small {
            line-height: 3rem;
            margin-right: 1rem;
        }

        .date_container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .icon_cross {
            width: 2.5rem;
            cursor: pointer;
            margin-right: -1rem;
        }

        .icon_cross:hover {
            color: red;
            transform: scale(1.3),
        }

        .icon_container {
            align-items: flex-start;
            display: flex;
            flex-direction: row-reverse;
        }

    }

    .notification_card:hover {
        background-color: #f3f0f0;
        transform: scale(0.98)
    }
}