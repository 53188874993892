#header {
	background-color: $white_color;
	margin-bottom: 4rem;
	@media (max-width: 991px) {
		margin-bottom: 13rem;
	}

	box-shadow: 0 0.4rem 2.5rem rgba(0, 0, 0, 0.1);
	.header_blk {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 13rem;
		.logo_blk {
			position: relative;
			z-index: 2;
			width: 24.3rem;
			height: 9.2rem;
		}
		h3 {
			margin-bottom: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			line-height: 6rem;

			@media (max-width: 991px) {
				display: none;
			}
		}
		ul {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;

			@media (max-width: 991px) {
				position: initial;
			}

			li {
				padding: 0 1.5rem;

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				&:nth-child(2) {
					position: relative;
				}
				.search_blk {
					display: flex;
					position: relative;
					align-items: center;
					border: 0.1rem solid #bdbec0;
					border-radius: 0.5rem;
					height: 6rem;
					min-width: 37.4rem;
					width: 37.4rem;				

					@media (max-width: 991px) {
						position: absolute;
						bottom: 0;
						top: 100%;
						left: 0;
						right: 0;
						transform: translate(0, 50%);
						width: 100%;
					}

					@media (max-width: 425px) {
						min-width: 28rem !important;
					}

					.img_blk {
						width: 4.8rem;
						min-width: 4.8rem;
						height: 4.8rem;
						margin: 0 1rem 0 2rem;

						img {
							width: 100%;
							display: block;
						}
					}
					input {
						border: initial;
						width: 100%;
						padding: 3px 1rem;
						padding-top: 10px;
						background-color: inherit;
					}
				}
				a.img_blk {
					display: block;
					width: 4.8rem;
					min-width: 4.8rem;
					height: 4.8rem;
					cursor: pointer;
					position: relative;

					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}
.sec_pad_top {
	padding-top: 4rem;
}
.sec_pad_btm {
	padding-bottom: 4rem;
}
.dot {
	min-width: 0.9rem;
	width: 0.9rem;
	height: 0.9rem;
	display: block;
	position: absolute;
	background-color: $prime_color;
	border-radius: 75%;
	top: 15%;
	left: 30%;
	border: 0.1rem solid $white_color;
}
