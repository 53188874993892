.public_header {
	background-color: $white_color;
	box-shadow: 0 0.4rem 2.5rem rgba(0, 0, 0, 0.1);
	.contain {
		max-width: 176rem;
		padding: 0 2rem;
		margin: 0 auto;
		.header_container {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 13rem;
			.logo_container {
				display: flex;
				position: relative;
				z-index: 2;
				width: 24.3rem;
				height: 9.2rem;
			}
			.buttons_container {
				display: flex;
				justify-content: end;
				// justify-content: space-between;
				align-items: center;
				width: 80rem;

				.nav_btn {
					cursor: pointer;
					font-size: 2.4rem;
					line-height: 3.6rem;
					font-weight: 500;
					margin-right: 5rem;
				}
				.login_nav_btn {
					cursor: pointer;
					font-size: 2rem;
					line-height: 3rem;
					font-weight: 500;
					padding: 2rem 5rem;
					background: $prime_color;
					color: $white_color;
					border-radius: 5px;
					border: 1px solid $prime_color;
					&:hover {
						color: $prime_color;
						background-color: $white_color;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.header_container {
		height: 11rem !important;
		.logo_container {
			width: 15rem !important;
			height: 7rem !important;
		}
		.buttons_container {
			width: 45rem !important;
			.nav_btn {
				font-size: 1.8rem !important;
			}
			.login_nav_btn {
				font-size: 1.6rem !important;
				padding: 1.4rem 3.5rem !important;
			}
		}
	}
}

@media (max-width: 425px) {
	.header_container {
		height: 11rem !important;
		.logo_container {
			width: 15rem !important;
			height: 7rem !important;
		}
		.buttons_container {
			width: 45rem !important;
			padding-left: 1.5rem;
			.nav_btn {
				font-size: 1rem !important;
			}
			.login_nav_btn {
				font-size: 1.1rem !important;
				padding: 0.2rem 1.4rem !important;
    			width: 6rem;
			}
		}
	}
}

