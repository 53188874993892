.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    min-height: 10rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
  .main_container{
    margin-top: 7rem;
  }
  .editor_heading{
    font-size: 3rem;
    font-weight: 500;
  }