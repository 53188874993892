#notification_popUP {
	.popup_notification {
		position: absolute;
		// top: 43.5%;
		// left: 75.5%;
		transform: translate(-46.5%, 2%);
		width: 47rem;
		background-color: #ffffff;
		border: 1px solid #d1d0d0;
		z-index: 11;
		border-radius: 1rem;
		box-shadow: 5px 13px 27px -11px rgba(0, 0, 0, 0.81);
		-webkit-box-shadow: 5px 13px 27px -11px rgba(0, 0, 0, 0.81);
		-moz-box-shadow: 5px 13px 27px -11px rgba(0, 0, 0, 0.81);
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
		min-height: 40rem;
		@media (max-width: 991px) {
			width: 27rem;
		}
		@media (min-width: 992px) {
			width: 40rem;
		}
	}

	.notification_container {
		background-color: #63a744;
		height: 7.5rem;
		min-height: 5rem;
		display: flex;
		justify-content: space-between;
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
	}

	.scroll {
		overflow-y: scroll;
		max-height: 40rem;
	}

	.notification_text {
		display: flex;
		justify-content: center;
		align-self: center;
		width: 100%;
		margin-top: 1.5rem;
	}
	.mark_all {
		padding: 1rem;
		color: #d5d5d5;
		cursor: pointer;
		align-self: flex-end;
		font-weight: 600;
		right: 0;
		margin-right: 1rem;
		position: absolute;
		@media (max-width: 991px) {
			font-size: 1rem !important;
		}
	}

	.mark_all:hover {
		text-decoration: underline;
		color: red;
	}

	.view_all {
		padding: 1rem;
		color: #d5d5d5;
		cursor: pointer;
		align-self: flex-start;
		left: 0;
		font-weight: 600;
		margin-left: 1rem;
		position: absolute;
		@media (max-width: 991px) {
			font-size: 1rem !important;
		}
	}

	.view_all:hover {
		text-decoration: underline;
		color: rgb(15, 97, 135);
	}

	.no_notification {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 15%;
		margin-bottom: 15%;
	}
	.triangle {
		position: absolute;
		top: -8px;
		left: 50.5%;
		height: 15px;
		width: 15px;
		border-radius: 6px 0px 0px 0px;
		transform: rotate(45deg);
		background-color: #63a744;
	}
	.notification_heading {
		font-size: 2.5rem;
		display: flex;
		justify-content: space-around;
		width: 20rem;
		font-weight: 500;
		@media (max-width: 991px) {
			font-size: 1.2rem !important;
			width: 10rem;
		}
	}
}
