.Paginatoritem {
	align-items: center;
	color: #fff !important;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	height: 5rem;
	justify-content: center;
	width: 5rem;
	margin-right: 0rem !important;
}

.disabled-page {
	color: #808e9b;
}

.activeTab {
	border: solid 0.5px #27388a !important;
	border-radius: 50px;
	color: #27388a;
}

.next {
	border-left: solid 1px #fff !important;
	font-size: 4px;
	height: 4rem;
	position: absolute;
	right: 0;
	width: 20rem;
}

.pagination {
	align-items: center;
	background-color: #63a744 !important;
	display: flex;
	flex-direction: row;
	height: 6rem;
	justify-content: center;
	list-style-type: none;
	position: relative;
	width: auto;
}

.pagination-page {
	font-weight: 600 !important;
	color: #fff !important;
}

.previous {
	border-right: solid 1px #fff !important;
	font-size: 4px;
	height: 4rem;
	left: 0;
	position: absolute;
	width: 20rem;
}

@media (max-width: 425px) {

	.Paginatoritem {
		width: 4rem;
		font-size: 12px;
	}

	.previous {
		width: 5rem;
	}

	.next {
		width: 5rem;
	}
}

@media (max-width: 375px) {

	.Paginatoritem {
		width: 4rem;
		font-size: 12px;
	}

	.previous {
		width: 2.75rem;
		margin: 0 0.7rem;
	}

	.next {
		width: 4rem;
	}
}

