#notification {
	#top_blk {
		margin-bottom: 4rem;
	}
	.notification_card {
		background-color: $white_color;
		padding: 1.7rem 2.4rem;
		border-radius: 1rem;
		margin: 1rem 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
        z-index: -1;

		.left_blk {
			width: 100%;
			padding: 1rem;
			p {
				font-size: 2rem;
				line-height: 3rem;
				margin-bottom: 1rem;
			}
			span {
				font-size: 2rem;
				line-height: 3rem;
				color: #bebebe;
			}
		}
		small {
			font-size: 2rem;
			line-height: 3rem;
		}
	}
}
