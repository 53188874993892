#news_article {
    .details_blk {
        background: #ffffff;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 3.2rem;
        margin: 4rem 0;
        .bg_blk {
            // background-image: url("../../../assets/images/eventbg.jpg");
            background-position: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: 30rem;
        }
        .firm-event-info {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 2rem;

            @media (max-width: 768px) {
                flex-direction: column;
            }
            h3 {
                line-height: 6rem;
            }
        }
        .firm-event-details {
            margin-top: 2rem;
            p {
                font-size: 2rem;
                line-height: 2.5rem;
                color: #7c7c82;
            }
        }
    }
}
